import React from 'react'
import { 
	CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import PropTypes from "prop-types";

const MyButton = (props) => {
	const {centered,iconname,children,className,...rest} = props
	
	//console.log("MyButton - props => "+JSON.stringify(props))	
	return (
		<CButton
			className={(centered?"mx-auto ":"")+"px-4 classtombol "+(className||"")}
			{...rest}>
			{(iconname||"")!=="" && (
			<><CIcon icon={iconname} 
				height={20}/>&nbsp;</>
			)}
			<>{children||"Label"}</>
		</CButton>

	)
}
MyButton.propTypes = {
	centered: PropTypes.bool,
	iconname: PropTypes.string,
}
MyButton.defaultProps = { 
	centered: false 
}

export default MyButton