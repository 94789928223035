import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CButton,
	CImage,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CSpinner,
	CLink,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	MyDialogform,
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import {
	UFunc,cAngkaTgl,cBulanPendek,cBulanPanjang 
} from '../helpers/functions'

const pjson 	= require('../../package.json')
const Lapkasir 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uSettingsistemObj			= useSelector(state => state.gSystemsetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 				= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});
	//const uFilterObj		  		= useSelector(state => state.gFilterObj) || {};

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatakasirArr,setDatakasirArr]= React.useState([]); 
	const [uDatasumArr,setDatasumObj]	= React.useState({});
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--*/

	//--FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	//--END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uHTMLstylenota		= useSelector(state => state.gHTMLstyleNota);
	const uLebarKertas			= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri			= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize				= parseInt(uSettingcetakObj.fontsize)||12;
	const uViewLogo				= uSettingcetakObj.view_image_nota ||"TIDAK";
	const uFontstyle			= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}
		vElfocus	= document.getElementById("inpjenis");
		if((uFormdataObj.jenis||"").trim() !== "TANGGAL"
			&& (uFormdataObj.jenis||"").trim() !== "BULAN") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Laporan Tidak Valid !");
			return
		}

		let vTanggal;
		if((uFormdataObj.jenis||"").trim() === "TANGGAL") {
			vElfocus	= document.getElementById("inptgl");
			vTanggal  	= uFormdataObj.thn+"-"+uFormdataObj.bln+"-"+uFormdataObj.tgl; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Tanggal Laporan Tidak Valid ! "); return
			}
		} else {
			vElfocus	= document.getElementById("inpbulanid");
			vTanggal  	= uFormdataObj.thn+"-"+uFormdataObj.bln+"-01"; 
			if(!UFunc.isDateValid(vTanggal)) {
				vElfocus && vElfocus.focus();
				showToast("Periode Bulan-Tahun Laporan Tidak Valid !"); return
			}
		}

		const vTanggalDT	= UFunc.toDate(vTanggal);
		if(UFunc.DateIsBeyond(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Periode Laporan Melewati Tanggal SAAT INI ! "); return
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoaddata();
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetaklaporan();
		if(vContent === "") return
		//console.log("(Lapkasir - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	const hdlKlikCetakcopy = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper	= (uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase()

		initLoadnota(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper	= (uDatamainArr[vArridx].no_nota||"UNDF").toUpperCase()

		if(await(Konfirm(
			"Hapus Nota <b>"+vCaptionupper+"</b>.."+
			"<br/>Proses HAPUS Tidak dapat DIULANG, dan Data Nota yang Dihapus Tidak Dapat Dikembalikan."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin Akan Menghapus ?</b>"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vKasirIdx		= UFunc.getObjectIndeks(uDatakasirArr,uFilterObj.kasir_id);
		const vKasirtext	= vKasirIdx >= 0 ? uDatakasirArr[vKasirIdx].nama_kasir : "";

		const vKasirID	= parseInt((uFilterObj||{}).kasir_id)||0;
		const vBulanID	= parseInt((uFilterObj||{}).bln)||0;
		const vTahun	= parseInt((uFilterObj||{}).thn)||0;

		let vColspan		= 3;
		if(uFilterObj.jenis==="TANGGAL") {
			vColspan = 8;
			if(vKasirtext!=="") vColspan = 7
		}

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= uFilterObj.jenis==="TANGGAL" ? ({
			no : "",
			no_nota: "",
			kasir_caption: "",
			pasien_caption: "",
			dokter_caption: "",
			payment_caption: "",
			nilai_transaksi: "",
			nilai_bayar: "",
		}) : ({
			no : "",
			tgl_kasir: "",
			nilai_bayar: "",
		})
		if(uFilterObj.jenis==="TANGGAL")
			if(vKasirtext!=="") delete vTmpObj.kasir_caption;//-*/
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				id,kasir_caption,pasien_caption,pasien_telp,no_nota,dokter_caption,
				payment_caption,nilai_transaksi,nilai_bayar,
				tgl_kasir,
			} = vItems;

			vRowcount++;
			vColposwidth = (pasien_caption||"").length > vColposwidth 
				? (pasien_caption||"").length : vColposwidth; 

			const vTglbayar	= UFunc.toDate(tgl_kasir);
			vTmpObj	= uFilterObj.jenis === "TANGGAL" ? ({
				no : {v:vKeys+1,t:"n"},
				no_nota: {v:(no_nota||"-"),t:"s",s:{wrapText:true}},
				kasir_caption: (kasir_caption||"Undf"),
				pasien_caption: (pasien_caption||"Undf Pasien"),
				dokter_caption: {v:(dokter_caption||"Undf Dokter"),t:"s",s:{wrapText:true}},
				payment_caption: {v:(payment_caption||"Tunai"),t:"s",s:{wrapText:true,font:{bold:true}}},
				nilai_transaksi: { v:parseFloat(nilai_transaksi), t:"n", s:{numFmt: "#,##0"} },
				nilai_bayar: { v:parseFloat(nilai_bayar), t:"n", s:{numFmt: "#,##0"} },
			}) : ({
				no : {v:vKeys+1,t:"n"},
				tgl_kasir: {v:vTglbayar,t:"d",s:{alignment:{horizontal:"left"}}},
				nilai_bayar: { v:parseFloat(nilai_bayar), t:"n", s:{numFmt: "#,##0"} },
			}) 
			if(uFilterObj.jenis==="TANGGAL")
				if(vKasirtext!=="") delete vTmpObj.kasir_caption;//-*/

			console.log(vRowcount+" => "+JSON.stringify(vTmpObj));
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= uFilterObj.jenis==="TANGGAL" ? ({
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			no_nota: "",
			kasir_caption: "",
			pasien_caption: "",
			dokter_caption: "",
			payment_caption: "",
			nilai_transaksi: "",
			nilai_bayar: { v:parseFloat(uDatasumArr.nilai_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		}) : ({
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_kasir : "",
			nilai_bayar: { v:parseFloat(uDatasumArr.nilai_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		});
		if(uFilterObj.jenis !== "TANGGAL") {
			delete vTmpObj.tgl_kasir;
		} else {
			if(vKasirtext!=="") delete vTmpObj.kasir_caption;
		}
		console.log(vRowcount+" => "+JSON.stringify(vTmpObj));
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wpx:130},
			{wpx:100},
			{wch:vColposwidth},
			{wpx:200},
			{wpx:150},
			{wpx:85},
			{wpx:85},
		];
		if(uFilterObj.jenis === "TANGGAL") {
			if(vKasirtext !== "") wscols.splice(2,1);
		}
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPKASIR_"+
			(uFilterObj.jenis==="TANGGAL"?uFilterObj.tgl:"")+
			(cBulanPendek[vBulanID-1]).toUpperCase()+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+(uFilterObj.jenis==="TANGGAL"?uFilterObj.tgl+" ":"")+
				(cBulanPendek[vBulanID-1]).toUpperCase()+" "+vTahun+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
			(parseInt(uFilterObj.kasir_id)>0 ? "\r\nKasir: "+vKasirtext : "")+
			((uFilterObj.metode||"0")!=="0" ? "\r\nMetode: "+uFilterObj.metode : "")+
		"";
		const vHeaderArr = uFilterObj.jenis === "TANGGAL" 
			? [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"No.Nota",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Kasir",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Dokter",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Metode Bayar",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Transaksi (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		] : [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		if(uFilterObj.jenis==="TANGGAL")
			if(vKasirtext!=="") vHeaderArr.splice(2,1);

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setCaptionObj({}); setDatamainArr([]);// setJmlData(0);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initLoadnota = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vDatanotaObj 	= uDatamainArr[vArridx].nota_obj || {};

		//console.log("(Lapkasir - initLoadnota) vDatanotaObj = "+JSON.stringify(vDatanotaObj))
		if(JSON.stringify(vDatanotaObj)!=="{}") {
			initCetaknota(_IDTABEL); return;
		}

		apiLoadnota(_IDTABEL);
	}
	const initCetaknota = (_IDTABEL) => {
		const vContent 	= contentCetaknota(_IDTABEL);
		if(vContent === "") return
		//console.log("(Lapkasir - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	//--END INIT--/

	//--CONTENT--/
	//console.log("uDatakasirArr => "+JSON.stringify(uDatakasirArr))
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		const vJmlTahunlaporan	= parseInt(uSettingsistemObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vKasirviewArr		=
			uDatakasirArr.filter(vItems=>(vItems.cabang_arr || []).includes(vCabangID.toString()))

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
			<CCol xs="7">Cabang</CCol>
			<CCol className="text-end">
			<CFormSelect size="sm"
				value={uFormdataObj.cabang_id||0}
				onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
				id="inpcabangid">
				{vCabangviewArr.map((vItems,vKeys)=>{
					const {
						id,cabang_nama
					} = vItems

					return (
					<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
					)
				})}
			</CFormSelect>
			</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="4">Jenis Laporan</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.jenis}
			onChange={(e)=>{uFormdataObj.jenis = e.target.value; setEfekview(!uEfekview)}}
			id="inpjenis">
			<option value="TANGGAL">per TANGGAL</option>
			<option value="BULAN">per BULAN</option>
			</CFormSelect>
		</CCol>
		</CRow>

		{(uFormdataObj.jenis||"TANGGAL")==="TANGGAL" ? (
		<CRow className="my-2">
		<CCol xs="4">Tanggal</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="ps-1"
				value={uFormdataObj.tgl}
				onChange={(e)=>{uFormdataObj.tgl = e.target.value; setEfekview(!uEfekview)}}
				id="inptgl">
				{cAngkaTgl.map((vItems,vKeys) => {
					return (
						<option value={UFunc.leadZero(vItems)} key={vKeys}>
						{UFunc.leadZero(vItems)}
						</option>
					)					
				})}
			</CFormSelect>
			&nbsp;
			<CFormSelect size="sm"
				className="ps-1"
				value={uFormdataObj.bln || UFunc.leadZero((new Date()).getMonth() + 1)}
				onChange={(e)=>{uFormdataObj.bln = e.target.value; setEfekview(!uEfekview)}}
				id="inpbln">
				{cBulanPendek.map((vItems,vKeys) => {
					return (
						<option value={UFunc.leadZero(vKeys+1)} key={vKeys}>
						{(vItems||"UNDF").toUpperCase()}
						</option>
					)					
				})}
			</CFormSelect>
			&nbsp;
			<CFormSelect size="sm"
				className="ps-1"
				value={uFormdataObj.thn || UFunc.leadZero((new Date()).getFullYear())}
				onChange={(e)=>{uFormdataObj.thn = e.target.value; setEfekview(!uEfekview)}}
				id="inpthn">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		) : (
		<CRow className="my-2">
		<CCol xs="4">Bulan</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				style={{width:"auto"}}
				value={uFormdataObj.bln || UFunc.leadZero((new Date()).getMonth() + 1)}
				onChange={(e)=>{uFormdataObj.bln = e.target.value; setEfekview(!uEfekview)}}
				id="inpbulanid">
				{cBulanPanjang.map((vItems,vKeys) => {
					return (
						<option value={UFunc.leadZero(vKeys+1)} key={vKeys}>
						{(vItems||"UNDF").toUpperCase()}
						</option>
					)					
				})}
			</CFormSelect>
			&nbsp;
			<CFormSelect size="sm"
				value={uFormdataObj.thn || UFunc.leadZero((new Date()).getFullYear())}
				onChange={(e)=>{uFormdataObj.thn = e.target.value; setEfekview(!uEfekview)}}
				id="inpbulantahun">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="4">Metode</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm" className="py-1"
				value={uFormdataObj.metode || ""}
				onChange={(e)=>{uFormdataObj.metode = e.target.value; setEfekview(!uEfekview)}}
				id="inpmetode">
				<option value="0">&middot;&middot; SEMUA METODE &middot;&middot;</option>
				<option value="TUNAI">T U N A I</option>
				<option value="NONTUNAI">N O N T U N A I</option>
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4">Kasir</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uFormdataObj.kasir_id || "0"}
				onChange={(e)=>{uFormdataObj.kasir_id = e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpkasirid">
				<option value="0">&middot;&middot; SEMUA KASIR &middot;&middot;</option>
				{vKasirviewArr.map((vItems,vKeys)=>{
				return (
					<option value={vItems.id} key={vKeys}>
					{(vItems.nama_kasir||"UNDF").toUpperCase()}
					</option>
				)
				})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentLaporan = () => {
		if(!uIsDataloaded) return (<></>)

		if(uJmlData <= 0) return (
			<div className="mx-md-5 p-3 p-md-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)

		//console.log("(Lapkasir - contentLaporan) uFilterObj.jenis => "+uFilterObj.jenis);
		if(uFilterObj.jenis === "TANGGAL") return (
			<>{contentPerTanggal()}</>
		)
		if(uFilterObj.jenis === "BULAN") return (
			<>{contentPerBulan()}</>
		)

		return (<></>)
	}
	const contentPerTanggal = () => {
		if(uFilterObj.jenis !== "TANGGAL") return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		const vKolkasir		= (uFilterObj.kasir_id||"0")==="0" ? 0 : 1;

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 1000;

		return (
		<div style={{overflowX:"auto"}}
			className=""
			id="iddivtabel">
		<table 
			style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" 
			id="idtabeldata">
		<thead>
			<tr className="text-start align-top classfontsmaller">
			<th width={45} className="px-1 text-center" style={vStylekolfixed}>No</th>
			
			<th width={130} className="px-1" style={{...vStylekolfixed,left:45}}>{uBahasaObj.caption_nonota||"Nota"}</th>
			
			{(uFilterObj.kasir_id||"0")==="0" && (
			<th width={125} className="px-1 border">{uBahasaObj.word_kasir||"Kasir"}</th>
			)}
			
			<th width={300} className="px-1 border">{uBahasaObj.caption_namapasien||"Nama Pasien"}</th>

			<th className="px-1 border">{uBahasaObj.word_keterangan||"Keterangan"}</th>
			
			<th width={125} className="px-1 text-end border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_bayar" ? " text-danger" : "")}>
				{uBahasaObj.word_nilai||"Nilai"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_bayar")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_bayar" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,kasir_caption,pasien_caption,pasien_telp,no_nota,dokter_caption,
				payment_caption,nilai_transaksi,nilai_bayar,
				init_processing,
			} = vItems;

			vNumber++;
			return (
			<tr id={"idtr"+id} className={"align-top "+(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
			<td className="px-1 text-end" style={vStylekolfixed}>{(vNumber)+"."}</td>

			<td className="text-start px-1 border-end" style={{...vStylekolfixed,left:45}}>
				<b className="text-uppercase">{(no_nota||"UNDF")}</b>
			</td>

			{(uFilterObj.kasir_id||"0")==="0" && (
			<td  className="text-start">
				<small>{UFunc.Usercaptionfilter(kasir_caption||"UNDF")}</small>
			</td>
			)}
			
			<td className="text-start px-1">
				<span className="text-capitalize text-info">{pasien_caption||"UNDF"}</span>
			</td>

			<td className="text-start px-1">
				<ul className="ps-4">
					<li>{dokter_caption||"UNDF"}</li>
					<li><small>Nilai Transaksi (Rp): <span className="text-primary">{UFunc.formatAngka(nilai_transaksi)}</span></small></li>
					{(uFilterObj.metode||"0")!=="TUNAI" && (
					<li><small>Metode Payment: <b>{payment_caption||"TUNAI"}</b></small></li>
					)}
				</ul>
			</td>

			<td className="px-1 text-end text-success">{UFunc.formatAngka(nilai_bayar)}</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td className="px-1 text-center text-uppercase" colSpan={(parseInt(uFilterObj.kasir_id)||0)===0 ? 5 : 4}>
			{uBahasaObj.word_jumlah||"JUMLAH"} 
		</td>

		<td className="px-1 text-end">
			{UFunc.formatAngka(uDatasumArr.nilai_bayar)}
		</td>
		</tr>
		</tfoot>

		</table>
		</div>
		)
	}
	const contentPerBulan = () => {
		if(uFilterObj.jenis !== "BULAN") return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		const vKolkasir		= (uFilterObj.kasir_id||"0")==="0" ? 0 : 1;
		
		return (
		<div className="mx-lg-5 px-lg-5">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="text-center align-top classfontsmaller">
			<th width={45} className="px-1 border">No</th>
			<th className="px-1 text-start border">Tgl Kasir</th>
			<th width="35%" className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_bayar" ? " text-danger" : "")}>
				Total Nilai (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_bayar")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_bayar" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				tgl_kasir,nilai_bayar
			} = vItems;

			vNumber++;
			return (
			<tr className={"align-top "+(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
			<td className="px-1 pe-0 text-end">{(vNumber)+"."}</td>
			<td className="px-1 text-start">
				<big className="fw-bolder d-none d-lg-block">{UFunc.TglAngka(tgl_kasir)}</big>
				<span className="fw-bolder d-lg-none">{UFunc.TglAngka(tgl_kasir)}</span>
			</td>
			<td className="px-1 text-end">
				<big className="text-success d-none d-lg-block">{UFunc.formatAngka(nilai_bayar)}</big>
				<span className="text-success d-lg-none">{UFunc.formatAngka(nilai_bayar)}</span>
			</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot className="fw-bolder bg-primary text-white">
		<tr>
		<td className="px-1 text-center" colSpan="2">JUMLAH</td>
		<td className="px-1 text-end">{UFunc.formatAngka(uDatasumArr.nilai_bayar)}</td>
		</tr>
		</tfoot>
		</table>
		</div>
		)
	}
	const contentCetaklaporan = () => {
		if(uDatamainArr.length <= 0) return "";
		//console.log("(Lapkasir - contentCetaklaporan) vHTMLs => "+vHTMLs)

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vKasirIdx		= UFunc.getObjectIndeks(uDatakasirArr,uFilterObj.kasir_id);
		const vKasirtext	= vKasirIdx >= 0 ? uDatakasirArr[vKasirIdx].nama_kasir : "";

		let vColspan		= 3;
		if(uFilterObj.jenis==="TANGGAL") {
			vColspan = 6;
			if(vKasirtext!=="") vColspan = 5
		}

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="`+vColspan+`">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 ? `<div>Cabang: `+vCabangtext+`</div>` : ``)+`
			`+(parseInt(uFilterObj.kasir_id)>0 ? "<div>Kasir: <b>"+vKasirtext+"</b></div>" : "")+`
			`+((uFilterObj.metode||"0")!=="0" ? "<div>Metode: <b>"+uFilterObj.metode+"</b></div>":"")+`
			</td>
			</tr>
			<tr class="idtabelheader">
			`;
		if(uFilterObj.jenis==="TANGGAL")
			vHTMLs += `
				<th width="5%">No</th>
				`+(vKasirtext==="" ? ` 
					<th width="10%" align="left">`+(uBahasaObj.word_kasir||"Kasir")+`</th>
					` : "")+`
				<th width="13%" align="left">`+(uBahasaObj.caption_nonota||"No.Nota")+`</th>
				<th width="18%" align="left">`+(uBahasaObj.caption_namapasien||"Nama Pasien")+`</th>
				<th align="left">`+(uBahasaObj.word_keterangan||"Keterangan")+`</th>
				<th width="15%">`+(uBahasaObj.word_nilai)+` (Rp)</th>
			`;
		else
			vHTMLs += `
				<th width="5%">No</th>
				<th align="left">`+(uBahasaObj.caption_tglkasir||"Tgl Kasir")+`</th>
				<th width="25%">`+(uBahasaObj.word_nilai)+` (Rp)</th>
			`;
		vHTMLs += `
			</tr>
			</thead>
			<tbody>`;
		uDatamainArr.forEach((vItems,vKeys)=>{
			const {
				id,kasir_caption,pasien_caption,pasien_telp,no_nota,dokter_caption,
				payment_caption,nilai_transaksi,nilai_bayar,
				tgl_kasir,
			} = vItems;

			if(uFilterObj.jenis==="TANGGAL")
				vHTMLs 	+= `
				<tr key={vKeys} valign="top">
					<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
					`+(vKasirtext==="" ? ` 
						<td align="left" style="font-size:smaller">`+(kasir_caption||"-")+`</td>
						` : "")+`
					<td align="left" style="font-size:smaller">`+(no_nota||"-")+`</td>
					<td align="left">`+
						pasien_caption+`
						`+((pasien_telp||"")!=="" 
							? "<div style='font-size:smaller;'>Telp/HP: "+(pasien_telp)+"</div>" 
							: "")+`
					</td>
					<td align="left">
						<ul>
						<li><small>`+(dokter_caption||"DRG.UNDF")+`</small></li>
						<li><small>Nilai Transaksi (Rp): `+UFunc.formatAngka(nilai_transaksi)+`</small></li>
						`+((payment_caption||"")!=="" 
							? "<li><small>Metode Payment: "+(payment_caption)+"</small></li>" 
							: "")+`
						</ul>
					</td>
					<td align="right">`+UFunc.formatAngka(nilai_bayar)+`</td>
				</tr>
				`;
			else
				vHTMLs 	+= `
				<tr key={vKeys} valign="top">
					<td align="right" style="">`+(vKeys+1)+`.</td>
					<td align="left" style="font-weight:bolder">`+UFunc.TglAngka(tgl_kasir)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_bayar)+`</td>
				</tr>
				`;
		});
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			<td colspan="`+(vColspan-1)+`" align="center">JUMLAH</td>
		`;
		if(uFilterObj.jenis==="TANGGAL")
			vHTMLs	+= `
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_bayar)+`</td>
			`;
		else
			vHTMLs	+= `
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_bayar)+`</td>
			`;
		vHTMLs += `
			</tr>
			</tfoot>
			</table>
		`;
		return vHTMLs		
	}
	const contentCetaknota = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return
		//console.log("(Lapkasir - contentCetaklaporan) vHTMLs => "+vHTMLs)

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vDatacetakObj	= uDatamainArr[vArridx].nota_obj || {};
		if(JSON.stringify(vDatacetakObj||{}) === "{}") return "";

		const vCabangArr	= uDatacabangArr;
		const vCabangID		= uFilterObj.cabang_id || "0";
		const vCabangIdx	= UFunc.getObjectIndeks(vCabangArr,vCabangID);
		const vCabangObj	= vCabangArr[vCabangIdx];

		const vUrllogoDefault	= pjson.homepage+"api/images/header_nota.png";

		let vHTMLs 	= uHTMLstylenota;
		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= uViewLogo==="YA";// (uSystemsettingObj.view_image_nota||"TIDAK")==="YA";
		const vURLlogo		= (uSystemsettingObj.url_image_nota||"").trim() === "" ? vUrllogoDefault
			: uSystemsettingObj.url_image_nota;//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(vDatacetakObj);
		vHTMLs 			+=  vMainbody;
		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="20mm" style="height:20mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= `
			<tr valign="top">
			<td colSpan="4" align="center">
			<hr/>-- C O P Y --
			</td>
			</tr>
			`;
		if((vCabangObj.telp_hp || "") !== "")
			vTelpPerusahaan = `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				(uBahasaObj.caption_telphp||"Telp/WA")+". "+
				UFunc.capitalize(vCabangObj.telp_hp || "").toUpperCase()+
				//`<div>`+vIsWithlogo+`</div>`+
			`</td>
			</tr>
			` + vTelpPerusahaan;
		//---END TELP--/

		const vFooternota = (vCabangObj.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(vCabangObj.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(vCabangObj.cabang_nama || "DENTES").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(vCabangObj.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_TRFOOTERNOTA_/g,vFooternota);

		return vHTMLs		
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Lapkasir - apiLoadawal) LEWaT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);

			const vTmpArr	= [
			{id:"1",nama_kasir:"Nama Kasir 1",cabangid_arr:[1,3,5,7]},
			{id:"2",nama_kasir:"Nama Kasir 2",cabangid_arr:[2,4,6,8]},
			{id:"3",nama_kasir:"Nama Kasir 3",cabangid_arr:[1,3,5,7]},
			{id:"4",nama_kasir:"Nama Kasir 4",cabangid_arr:[1,3,5,7]},
			{id:"5",nama_kasir:"Nama Kasir 5",cabangid_arr:[2,4,6,8]},
			{id:"6",nama_kasir:"Nama Kasir 6",cabangid_arr:[1,3,5,7]},
			{id:"7",nama_kasir:"Nama Kasir 7",cabangid_arr:[2,4,6,8]},
			]

			setDatakasirArr(vTmpArr);
		},2500); return
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ setDlgformshow(false); setLoading(true); prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setLoading(false);
			setSessionaktif(true);
			if(output_string.tampil) {
				setDatakasirArr(JSON.parse(output_string.datakasir||"[]"));
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoadawal) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				setHtml500msg(vMsg);
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapkasir - apiLoadawal) catch-error : "+error);
			//showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddata = (_PAGE) => {
		_PAGE		= parseInt(_PAGE) || 0;

		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;
		/*
		const vHandelview	= (_PAGE <= 0 || uFirstload==="YA")?"YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==_PAGE);
			}
		}//-*/

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",kasir_caption:"Kasir 1",pasien_caption:"Nama Pasien 1",pasien_telp:"0817652352",no_nota:"2022837436",
				dokter_caption:"Andi Dasussalam,DRG",payment_caption:"CC BCA",
				nilai_transaksi:250000,nilai_bayar:250000},
			{id:"2",kasir_caption:"Kasir 2",pasien_caption:"Nama Pasien 2",pasien_telp:"0817652352",no_nota:"2022837436",
				dokter_caption:"Andi Dasussalam,DRG",payment_caption:"CC BCA",
				nilai_transaksi:300000,nilai_bayar:125000},
			{id:"2",kasir_caption:"Kasir 3",pasien_caption:"Nama Pasien 3",pasien_telp:"0817652352",no_nota:"2022837436",
				dokter_caption:"Andi Dasussalam,DRG",payment_caption:"CC BCA",
				nilai_transaksi:300000,nilai_bayar:205000},
		]
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);

			const vTmpFilterObj = uFormdataObj;
			vTmpFilterObj.init_change = undefined;
			setFilterObj(vTmpFilterObj);

			setDatamainArr(vTmpObj);
			//setJmlData(vTmpObj.length);

			hdlToggleDlgform();
			setDataloaded(true);
		},2500); return
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			//send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			//send_maxdata	: uMaxData,
			send_firstload	: uFirstload,//vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_laporan";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			setSessionaktif(true);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//setJmlData(vLoadArr.length);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				hdlToggleDlgform();
				setDataloaded(true);
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoaddata) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapkasir - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);

		setLoading(true);
		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setLoading(false);

			uDatamainArr.splice(vArridx,1);
			setRowselect(-1);
		},2500); return
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tabelid	: _IDTABEL,//vHandelview,
			send_cabangid	: uFilterObj.cabang_id || localStorage.getItem("lastcabangid"),//vHandelview,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiProseshapus) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapkasir - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadnota = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].init_processing = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			uDatamainArr[vArridx].init_processing = false;
			setEfekview2(!uEfekview2);

			const vTmpObj = {};
			//if(JSON.stringify(vTmpObj) === "{}") { showToast("Expired Result !"); return; }

			uDatamainArr[vArridx].nota_obj = vTmpObj;
			initCetaknota(_IDTABEL);
		},2500); return
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tabelid	: _IDTABEL,
			send_cabangid	: uFilterObj.cabang_id || localStorage.getItem("lastcabangid"),//vHandelview,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_nota";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			uDatamainArr[vArridx].init_processing = false;
			setSessionaktif(true);
			if(output_string.tampil) {
				setEfekview2(!uEfekview2);
				const vTmpObj = JSON.parse(output_string.datanota||"{}");
				if(JSON.stringify(vTmpObj) === "{}") {
					showToast("Expired Result !"); return;
				}

				uDatamainArr[vArridx].nota_obj = vTmpObj;
				initCetaknota(_IDTABEL);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoadnota) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].init_processing = false;
			console.log("(Lapkasir - apiLoadnota) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadawal();

		return () => {
			setDlgformshow(false);
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDatamainArr([]);setDatakasirArr([]);
			setDataloaded(false)
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uDatamainArr.length <= 0)
			{ setDatadefaultArr([]); return }
		setDatadefaultArr([{init_change:"1"},...uDatamainArr]) 
	},[uDatamainArr])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length)
		if(uDatamainArr.length <= 0)
			{ setDatasumObj({}); return }

		const vSumtotal	= uDatamainArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_bayar||"0"), 0);
		setDatasumObj({nilai_bayar: vSumtotal});
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		/*//--LOAD_PER_PAGE--/
		if(uFirstload==="YA") return;

		setIDselect(0);
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
		//--END LOAD_PER_PAGE--*/

		//--LOAD_ALL_VIEW_PER_PAGE--/
		setIDselect(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		setHeaderDlgform("Pilih Filter Laporan")
		setFormdataObj({...uFilterObj, init_change: 1});
		setDlgformshow(true)
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) { apiLoadawal(); }
		else { setDataloaded(false); }
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		if(uFormdataObj.jenis === "TANGGAL") {
			vTitelmain += "<b>"+UFunc.HariAngka(uFormdataObj.thn+"-"+uFormdataObj.bln+"-"+uFormdataObj.tgl)+"</b>";
		} else {
			vTitelmain += "BULAN <b>"+cBulanPanjang[parseInt(uFormdataObj.bln)-1]+" "+uFormdataObj.thn+"</b>";
		}

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr[vCabangIdx].cabang_nama;

		if((uFormdataObj.metode||"").toUpperCase() === "TUNAI") {
			vTitelsub	+= " &middot; <b>METODE TUNAI</b>";
		} else if((uFormdataObj.metode||"").toUpperCase() === "NONTUNAI") {
			vTitelsub	+= " &middot; <b>METODE NON TUNAI</b>";
		}
		if(parseInt(uFormdataObj.kasir_id) > 0) {
			const vKasirIdx= UFunc.getObjectIndeks(uDatakasirArr,uFormdataObj.kasir_id);
			vTitelsub	+= " &middot; "+uDatakasirArr[vKasirIdx].nama_kasir;
		} else {
			vTitelsub	+= " &middot; [SEMUA KASIR]";
		}

		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Lapkasir - [uIsDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uIsDlgformshow) 
			{ setFormdataObj({}); return }

		//console.log("(Lapkasir - [uIsDlgformshow]) uFormdataObj => "+JSON.stringify(uFormdataObj));
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			const vElfocus = document.getElementById("inpcabangid");
			vElfocus && vElfocus.focus();
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");
		if(uFormdataObj.jenis === undefined)
			uFormdataObj.jenis = "TANGGAL";
		if(uFormdataObj.metode === undefined)
			uFormdataObj.metode = "0";
		if(uFormdataObj.kasir_id === undefined)
			uFormdataObj.kasir_id = "0";

		const vTanggalNow	= new Date();
		if(uFormdataObj.tgl === undefined)
			uFormdataObj.tgl = UFunc.leadZero(vTanggalNow.getDate());
		if(uFormdataObj.bln === undefined)
			uFormdataObj.bln = UFunc.leadZero(vTanggalNow.getMonth()+1);
		if(uFormdataObj.thn === undefined)
			uFormdataObj.thn = UFunc.leadZero(vTanggalNow.getFullYear());
	},[uFormdataObj,
	uFormdataObj.cabang_id,
	uFormdataObj.jenis,
	uFormdataObj.metode,
	uFormdataObj.tgl,
	uFormdataObj.bln,
	uFormdataObj.thn,
	uFormdataObj.kasir_id,
	])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); setIDselect(0); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		const vNamasort = (uSortedObj.nama||"").toLowerCase();
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]);
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			//const vDefaultsortArr = uDatadefaultArr; 
			//vSortedArr = uDatamainArr.sort((a, b) => -1);
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])
	
	//console.log("(Lapkasir) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg) return (
		<CCard className="classbgcard">
		<CCardHeader className="fw-bolder">Page 500</CCardHeader>
		<CCardBody className="mx-md-5 p-md-5">
		<div className="fst-italic">
		{UFunc.renderHTML(uHtml500msg)}
		</div>
		</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
		<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
		<CCardBody className="mx-lg-5 p-lg-5">
		<div className="mx-md-5 p-md-5 fst-italic classpetunjuk">
		*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
		</div>
		</CCardBody>
		</CCard>
		) : (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between">
		<div>
			<div>{UFunc.renderHTML(uCaptionObj.title)}</div>
			<small>
				<small className="classfontsmaller text-primary">
				{UFunc.renderHTML(uCaptionObj.title_sub)}
				</small>
			</small>
		</div>

		<div className="classfontsmaller">
			<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikExcel()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>

			<span className="d-none d-md-inline">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikontabel classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>
				<span className="mx-1">&middot;</span>
				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikontabel classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
			</span>

			<span className="me-2">&middot;</span>
			<small className="text-muted">{UFunc.formatAngka(uTimeelapse)} ms</small>
		</div>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentLaporan()}</CCardBody>

		{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
		)}
		</CCard>
		</>
		)}
		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		<div className="d-none">
			{(uSystemsettingObj.url_image_nota||"")!=="" && (
				<img src={uSystemsettingObj.url_image_nota} height="100"/>
			)}
		</div>
		</>
	)
}	

export default Lapkasir