import React from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderBrand,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CBadge,
	CDropdown, CDropdownToggle, CDropdownMenu, CDropdownHeader, CDropdownItem, CDropdownDivider
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyDialogform,
	MyDialogpasien,
	MyDialoglookup,
	MyHeaderProfile,
	MyCardformpasien,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Daftarantri = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { tabel_id } 				= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHtml500msg,setHtml500msg]		= React.useState(); 
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 

	const uPasienID 						= parseInt(tabel_id || "0")
	//const [uDataconfigObj,setDataconfigObj]	= React.useState({})
	const [uDatadetilObj,setDatadetilObj]	= React.useState({})
	const [uDatatabelArr,setDatatabelArr]	= React.useState([])
	const [uJmlData,setJmlData]				= React.useState(0)
	const [uRowselect,setRowselect]			= React.useState(-1);
	const [uIDselect,setIDselect]			= React.useState(0);

	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uInpcabangid,setInpcabangid]		= React.useState(parseInt(uTokenObj.user_cabangid||"0"));
	//const uCabanginitObj					= uDatacabangArr[UFunc.getObjectIndeks(uDatacabangArr,uInpcabangid)]
	//const uCabanginitObj 					= useSelector(state => state.gCabangatribut) || {};
	const uCabangselectedObj 				= useSelector(state => state.gCabangatribut) || {};
	const [uCabanginitObj,setCabanginitObj] = React.useState(uCabangselectedObj);
	const uDlgPasiendone					= useSelector(state => state.gInitprosespasien) || false;

	//--DLG_FORM--/
	const [uDlgformjenis,setDlgformjenis]	= React.useState("")
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	const [uInpformcabangid,setInpformcabangid]	= React.useState(0);
	const [uInpTglantri,setInpTglantri]		= React.useState(UFunc.DbDate());
	const [uInpDoktercaption,setInpDoktercaption]= React.useState("");
	const [uInpDokterid,setInpDokterid]		= React.useState(0);
	const [uInpJamawal,setInpJamawal]		= React.useState("");
	const [uInpJamakhir,setInpJamakhir]		= React.useState("");
	const [uInpNoruang,setInpNoruang]		= React.useState(1);
	const [uInpKeterangan,setInpKeterangan]	= React.useState("");
	const [uInpKehadiran,setInpKehadiran]	= React.useState("BELUM HADIR");
	const [uDatadokterArr,setDatadokterArr]	= React.useState([]);

	const [uDlgPasiensimpan,setDlgPasiensimpan]	= React.useState(false);
	const [uDlgformPasienID,setDlgformPasienID]		= React.useState(0);
	//--END DLG_FORM--/

	//---DLG_PASIEN--/
	const [uIsDlgpasienshow,setDlgpasienshow]= React.useState(false);
	const [uDlgPasienID,setDlgPasienID]		= React.useState(0);
	//---END DLG_PASIEN--/

	//---DLG_LOOKUP--/
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupheader,setDlglookupheader]	= React.useState("");
	const [uInpDlglookup,setInpDlglookup]		= React.useState("");
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([]);
	//---END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikBack = () => {
		if(uUrlbefore === "") { uNavigate(-1); return }
		
		uNavigate(uUrlbefore,{replace:true})
	}
	const hdlKlikReset = () => { 
		setInpcabangid(0);
		apiLoadawal(); 
		return

		if(parseInt(uInpcabangid) === parseInt(uTokenObj.user_cabangid||"0")) {
		} else
			setInpcabangid(parseInt(uTokenObj.user_cabangid||"0"));
	}
	const hdlKlikTambah = () => {
		setDlgformjenis("ANTRIAN");

		setDlgformheader(uBahasaObj.caption_inputreservasi||"Input Reservasi");
		setIDselect(0); setRowselect(-1);
		setInpformcabangid(uInpcabangid);
		setDlgformshow(true);
	}
	const hdlKlikRM = () => {
		uNavigate("/subpasien/pasien/rekammedis/"+uPasienID);
	}
	const hdlKlikPasien = () => {
		setDlgPasienID(uPasienID);
		setDlgpasienshow(true);
	}
	const hdlKlikEditpasien = () => {
		setDlgformjenis("EDITPASIEN");
		setDlgformPasienID(uPasienID);

		setIDselect(0); setRowselect(-1);
		setDlgformheader(uDatadetilObj.pasien_caption);
		setDlgformshow(true);
	}
	const hdlKlikFotopasien = () => {
		uNavigate("/subpasien/pasien/fotopasien/"+uPasienID);
	}
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
		const vCaptionupper = UFunc.HariAngka( uDatatabelArr[vArridx].tgl_antri ).toUpperCase();

		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);

		setIDselect(_IDTABEL);
		setDlgformjenis("ANTRIAN");
		setDlgformheader("Edit Reservasi: "+vCaptionupper);
		setDlgformshow(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = UFunc.HariAngka(uDatatabelArr[vArridx].tgl_antri).toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlToggleDlgpasien = () => {setDlgpasienshow(false)}
	const hdlToggleDlgform = () => {setDlgformshow(false)}
	const hdlKDowndoktercaption = (_EV) => {
		setInpDokterid("0")
		if(_EV.keyCode !== 13)  return;
		if(uInpDoktercaption.trim() === "") return;

		_EV.preventDefault()
		_EV.stopPropagation()

		const vElfocus = document.getElementById("inpjamawal");
		const vfilterArr = uDatadokterArr.filter(vItems => 
			vItems.dokter_caption.toUpperCase().includes(uInpDoktercaption.trim().toUpperCase())
		)

		if(vfilterArr.length === 1) {
			setInpDokterid(vfilterArr[0].id||"0")
			setInpDoktercaption(vfilterArr[0].dokter_caption||"")
			vElfocus && vElfocus.focus();
			return
		}

		hdlKlikLookupdokter()
		return;
	}
	const hdlKlikLookupdokter = () => {
		const vDatalookupArr = uDatadokterArr.map(vItems =>{ 
			return {id: vItems.id,caption:vItems.dokter_caption} 
		})

		//console.log("(Forminvoice - hdlKlikCustomerlookup) uDatacustomerArr => "+JSON.stringify(uDatacustomerArr)); 
		//console.log("(Forminvoice - hdlKlikCustomerlookup) vDatalookupArr => "+JSON.stringify(vDatalookupArr)); 
		setDataDlglookupArr(vDatalookupArr)
		setDlglookupheader((uBahasaObj.word_lookup||"Lookup")+": "+
			(uBahasaObj.caption_datadokter||"Data Dokter"))
		setInpDlglookup(uInpDoktercaption)
		setInpDoktercaption("")
		setInpDokterid("0")

		setDlglookupshow(true)
	}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformjenis === "ANTRIAN") { prosesAntrian(); return }
		if(uDlgformjenis === "EDITPASIEN") {
			setDlgPasiensimpan(true);
			return
		}
	}
	const prosesAntrian = () => {
		let vElfocus = document.getElementById("inptglantri");
		if(uInpTglantri === "") {
			vElfocus && vElfocus.focus()
			showToast("Tgl Antrian Tidak Valid !"); return
		}
		const vTglantriDT = UFunc.toDate(uInpTglantri);
		if(UFunc.DateIsMundur(vTglantriDT)) {
			vElfocus && vElfocus.focus()
			showToast("Tgl Antrian Sudah Kadaluwarsa !"); return
		}

		if(parseInt(uInpDokterid) > 0) {
			vElfocus = document.getElementById("inpjamawal");
			if((uInpJamawal||"") === "") {
				vElfocus && vElfocus.focus();
				showToast("Jenis Antrian Merupakan Antrian JANJIAN.."+
					"<br/>Jam Janjian AWAL Harus Diisi !"+
					""); return;
			}

			vElfocus = document.getElementById("inpjamakhir");
			if((uInpJamakhir||"") === "") {
				vElfocus && vElfocus.focus();
				showToast("Jenis Antrian Merupakan Antrian JANJIAN.."+
					"<br/>Jam Janjian AKHIR Harus Diisi !"+
					""); return
			}

			const vTimeAwalDT	= UFunc.toDatetime(uInpTglantri+" "+uInpJamawal+":00");
			const vTimeAkhirDT	= UFunc.toDatetime(uInpTglantri+" "+uInpJamakhir+":00");

			if(vTimeAwalDT > vTimeAkhirDT) {
				vElfocus && vElfocus.focus();
				showToast("Jam Janjian AWAL Lebih Besar dari Jam Janjian AKHIR !"); return
			}

			vElfocus = document.getElementById("inpnoruang");
			if((parseInt(uInpNoruang)||0) <= 0
				|| (parseInt(uInpNoruang)||0) > (parseInt(uCabanginitObj.jml_ruangan)||2)) {
				vElfocus && vElfocus.focus();
				showToast("No Ruangan Tidak Valid !"); return
			}
		}

		apiProsessimpan(uIDselect);
	}
	const hdlToggleDlgLookup = () => { setDlglookupshow(false)}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0
		if(_IDITEM <= 0) return

		hdlToggleDlgLookup();
		const vArridx 	= UFunc.getObjectIndeks(uDatadokterArr,_IDITEM)

		setInpDokterid(uDatadokterArr[vArridx].id||"0")
		setInpDoktercaption(uDatadokterArr[vArridx].dokter_caption||"")

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)

			const vElfocus = document.getElementById("inpjamawal");
			vElfocus && vElfocus.focus();
		},350)
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initDlgformawal = () => {
		setDlgformjenis("");
		setInpformcabangid(uInpcabangid);
		setInpTglantri(UFunc.DbDate());
		setInpDoktercaption("");
		setInpDokterid(0);
		setInpJamawal("");
		setInpJamakhir("");
		setInpNoruang(1);
		setInpKeterangan("");
		setInpKehadiran("BELUM HADIR");

		setIDselect(0);
		setDlgformPasienID(0);
	}
	const initDlgformedit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx = UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);

		setInpKehadiran(uDatatabelArr[vArridx].status_hadir||"BELUM HADIR");
		setInpDokterid(uDatatabelArr[vArridx].dokter_id||0);
		setInpDoktercaption(uDatatabelArr[vArridx].dokter_caption||"");
		setInpJamawal(uDatatabelArr[vArridx].jam_awal||"");
		setInpJamakhir(uDatatabelArr[vArridx].jam_akhir||"");
		setInpNoruang(parseInt(uDatatabelArr[vArridx].no_ruang)||1);
		setInpKeterangan(uDatatabelArr[vArridx].keterangan||"");
		setInpTglantri(uDatatabelArr[vArridx].tgl_antri||UFunc.DbDate());
		setInpformcabangid(uDatatabelArr[vArridx].id_cabang||uInpcabangid);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentSubheader = () => {
		if(uHtml500msg) return (
		<>
			<b className="text-info">Error Page 500</b>
			<CTooltip content={"--"+(uBahasaObj.caption_resetview||"Refresh Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer"/>
			</CTooltip>
		</>
		)//->

		if(!isMobile) return contentSubdesktop();
		if(isMobile) return contentSubmobile();

		return "";
	}
	const contentSubdesktop = () => {
		if(uHtml500msg) return "";

		const vTgllahirDT 	= (uDatadetilObj.tgl_lahir||"")===""?null:UFunc.toDate(uDatadetilObj.tgl_lahir);
		const vTglnowDT		= new Date();
		const vISBirthdaytoday = vTgllahirDT 
			? (vTgllahirDT.getDate() === vTglnowDT.getDate() &&
				vTgllahirDT.getMonth() === vTglnowDT.getMonth()) : false;

		return (
		<>
			<div className="w-75">
				<big className="text-info fw-bold">{uDatadetilObj.pasien_caption||"UNDF"}</big>
				{uTokenObj.userhak !== "FO" && (
				<>
					<span className="mx-1">&middot;</span>
					<b style={{color:((uDatadetilObj.sex||"")==="P"?"pink":"navy")}}>
						[{uDatadetilObj.sex||"L"}]
					</b>
				</>
				)}
				{(uDatadetilObj.asuransi_caption||"")!=="" && (
				<>
					<span className="mx-1 d-none d-md-inline">&middot;</span>
					<CBadge color="light" className="d-none d-md-inline" style={{border:"1px solid purple"}}>
					<small className="fst-italic" style={{color:"purple"}}>{uDatadetilObj.asuransi_caption}</small>
					</CBadge>
				</>
				)}

				{vISBirthdaytoday && (
				<>
					<span className="mx-1">&middot;</span>
					<CTooltip content="--Pasien Berulang Tahun Hari Ini--">
					<CImage src={pjson.homepage+"api/images/icon_birthday.png"} className="classblinking" height={30}/>
					</CTooltip>
				</>
				)}

				<div className="classborderbottom my-2"/>

				<small>
					{uDatacabangArr.length > 1 && (<>{contentCabang()}</>)}

					{((uDatadetilObj.telp_hp||"")!=="" && (uTokenObj.userhak||"")!=="DOKTER") && (
					<>
						<span className="mx-1">&middot;</span>
						{uBahasaObj.caption_telphp||"Telp/HP"}. <b className="text-success">{uDatadetilObj.telp_hp}</b>
						<span className="mx-1">&middot;</span>
					</>
					)}
				</small>
			</div>

			<div className="w-25 text-center ps-2">
				<CTooltip content={"--"+(uBahasaObj.caption_inputantribaru||"Input Antrian Baru")}>
				<CLink onClick={()=>hdlKlikTambah()} className="classikon classikontambah classcursorpointer" height={32}/>
				</CTooltip>

				<span className="me-1">&middot;</span>
				
				<CTooltip content={"--"+(uBahasaObj.caption_resetview||"Refresh Tampilan")}>
				<CLink onClick={()=>hdlKlikReset()} className="classikontabel classikonreset classcursorpointer"/>
				</CTooltip>
				
				<span className="me-1">&middot;</span>

				<CDropdown>
				<CDropdownToggle className="p-0" color="transparent" caret={false}>
					<CIcon icon="cilChevronBottom" className="classikonbox mx-0" height={28}/>
				</CDropdownToggle>

				<CDropdownMenu>
				<CDropdownItem onClick={()=>hdlKlikRM()} className="classcursorpointer">
					<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
					{(uBahasaObj.caption_lihatrm||"Lihat Rekam Medis")}
				</CDropdownItem>

				<CDropdownItem onClick={()=>hdlKlikFotopasien()} className="classcursorpointer">
					<CImage src={pjson.homepage+"api/images/icon_foto.png"} className="align-middle mx-0 me-2" height={25}/>
					{(uBahasaObj.caption_fotopasien||"Foto Pasien")}
				</CDropdownItem>

				<CDropdownDivider/>
				<CDropdownItem onClick={()=>hdlKlikPasien()} className="classcursorpointer">
					<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2" height={25}/>
					{(uBahasaObj.caption_lihatdetilpasien||"Lihat Data Pasien")}
				</CDropdownItem>

				{(uTokenObj.userhak||"")!=="DOKTER" && (
				<CDropdownItem onClick={()=>hdlKlikEditpasien()} className="classcursorpointer">
					<CIcon icon="cilPencil" className="classikonbox mx-0 me-2" height={22}/>
					{(uBahasaObj.caption_editdatapasien||"Edit Data Pasien")}
				</CDropdownItem>
				)}
				</CDropdownMenu>
				</CDropdown>
			</div>
		</>
		)//->
	}
	const contentSubmobile = () => {
		if(uHtml500msg) return "";

		const vTgllahirDT 	= (uDatadetilObj.tgl_lahir||"")===""?null:UFunc.toDate(uDatadetilObj.tgl_lahir);
		const vTglnowDT		= new Date();
		const vISBirthdaytoday = vTgllahirDT 
			? (vTgllahirDT.getDate() === vTglnowDT.getDate() &&
				vTgllahirDT.getMonth() === vTglnowDT.getMonth()) : false;

		return (
		<>
		<div className="d-flex w-100 justify-content-between mb-2 pb-1 classborderbottom">
			<div className="">
			<b className="text-info">{UFunc.Usercaptionfilter(uDatadetilObj.pasien_caption||"UNDF")}</b>
			{uTokenObj.userhak !== "FO" && (
			<>
				<span className="mx-1">&middot;</span>
				<b style={{color:((uDatadetilObj.sex||"")==="P"?"pink":"navy")}}>
					[{uDatadetilObj.sex||"L"}]
				</b>
			</>
			)}

			{(uDatadetilObj.asuransi_caption||"")!=="" && (
			<>
				<span className="mx-1 d-none d-md-inline">&middot;</span>
				<CBadge color="light" className="d-none d-md-inline" style={{border:"1px solid purple"}}>
				<small className="fst-italic" style={{color:"purple"}}>{uDatadetilObj.asuransi_caption}</small>
				</CBadge>
			</>
			)}

			{vISBirthdaytoday && (
			<>
				<span className="mx-1">&middot;</span>
				<CTooltip content="--Pasien Berulang Tahun Hari Ini--">
				<CImage src={pjson.homepage+"api/images/icon_birthday.png"} className="classblinking" height={30}/>
				</CTooltip>
			</>
			)}
			</div>

			<div className="w-auto">
				<CLink onClick={()=>hdlKlikTambah()} className="classikon classikontambah classcursorpointer"/>

				<CDropdown>
				<CDropdownToggle color="transparent" className="p-0" caret={false}>
					<CIcon icon="cilChevronBottom" className="classikonbox" height={28}/>
				</CDropdownToggle>

				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikReset()}>
					<CIcon className="classikonreset mx-0 me-2 align-middle" height={25}/>Reset Tampilan
					</CDropdownItem>
					
					<CDropdownItem onClick={()=>hdlKlikRM()}>
					<CImage className="mx-0 me-2 align-middle" src={pjson.homepage+"api/images/icon_rekammedis.png"}
						height={25}/>{uBahasaObj.caption_lihatrm||"Lihat RM"}
					</CDropdownItem>
					
					<CDropdownItem onClick={()=>hdlKlikFotopasien()}>
					<CImage className="mx-0 me-2 align-middle" 
						src={pjson.homepage+"api/images/icon_foto.png"}
						height={25}/>{uBahasaObj.caption_fotopasien||"Foto Pasien"}
					</CDropdownItem>

					<CDropdownDivider/>
					
					<CDropdownItem onClick={()=>hdlKlikPasien()}>
					<CIcon icon="cilFindInPage" className="classikonbox mx-0 me-2 align-middle"
						height={25}/>{uBahasaObj.caption_lihatdetilpasien||"Detil Pasien"}
					</CDropdownItem>
					
					{uTokenObj.userhak !== "DOKTER" && (
					<CDropdownItem onClick={()=>hdlKlikEditpasien()}>
					<CIcon icon="cilPencil" className="classikonbox mx-0 me-2 align-middle"
						height={23}/>{uBahasaObj.caption_editpasien||"Edit Data Pasien"}
					</CDropdownItem>
					)}
				</CDropdownMenu>
				</CDropdown>
			</div>
		</div>

		<small className="d-block">
			{uDatacabangArr.length > 1 && (<>{contentCabang()}</>)}

			{((uDatadetilObj.telp_hp||"")!=="" && (uTokenObj.userhak||"")!=="DOKTER") && (
			<>
			<span className="mx-1">&middot;</span>
			{uBahasaObj.caption_telphp||"Telp/HP"}. <b className="text-success">{uDatadetilObj.telp_hp}</b>
			<span className="mx-1">&middot;</span>
			</>
			)}
		</small>
		</>
		)//->
	}
	const contentData = () => {
		if(uHtml500msg) return (
			<div className="mx-3">{UFunc.renderHTML(uHtml500msg)}</div>
		)

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1"><small>No</small></th>
			<th className="px-1">
			<small>
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.tgl_antrian||"Tgl Antrian"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_jenisantrian||"Jenis Antrian"}</CCol>
			<CCol md="5" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>

		<tbody>
		{uDatatabelArr.map((vItems,vKeys)=>{
			const {
				id,tgl_antri,dokter_id,dokter_caption,urutan,jam_awal,jam_akhir,
				keterangan,no_ruang,
				tgl_input,userinput
			} = vItems;

			const vNumber = vKeys+1;
			const vCaptionupper	= (UFunc.HariAngka(tgl_antri)).toUpperCase();
			const vJenisantrian	= parseInt(dokter_id||0)<="" ? "UMUM" : "JANJIAN";

			const vTglInputcaption = UFunc.TglAngka(tgl_antri) === UFunc.TglAngkaDT(tgl_input) ? ""
				: (<>{UFunc.WaktuAngka(tgl_input)} &middot; </>)

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
			<td className="px-1 pe-0">{vNumber}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
				<div>
				<div>{UFunc.HariAngka(tgl_antri)}</div>
				
				<div className="text-danger classfontsmaller d-none d-md-block">
				<small>[{vTglInputcaption}{UFunc.Usercaptionfilter(userinput,2)}]</small>
				</div>
				</div>

				<CDropdown className="d-md-none">
				<CDropdownToggle color="transparent" className="p-0" caret={false}>
					<CIcon icon="cilOptions" className="mx-0" height={18}/>
				</CDropdownToggle>

				<CDropdownMenu className="py-0">
					<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
					<CIcon className="classikonedit mx-0 me-2 align-middle" height={25}/>
					{uBahasaObj.caption_editantrian||"Edit Antrian"}
					</CDropdownItem>

					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
					<CIcon className="classikonhapus mx-0 me-2 align-middle" height={25}/>
					{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
					</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>
			</CCol>

			<CCol xs="12" className="px-1 my-3 classborderbottom d-md-none"/>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_jenisantrian||"Jenis Antrian"}</CCol>
			<CCol xs="8" md="2" className="px-1 text-md-center">
				<div className={(vJenisantrian==="UMUM" ? "" : "fw-bold")}>{vJenisantrian}</div>
				{vJenisantrian!=="UMUM" && (
					<small className="text-primary d-none d-md-block">Ruang {no_ruang||1}</small>
				)}
			</CCol>
			
			<CCol md="5" className="px-1 text-md-start d-none d-md-block">
				{(keterangan||"")!=="" && (
					<div><small className="text-capitalize fst-italic">{keterangan}</small></div>
				)}

				{vJenisantrian==="JANJIAN" ? (
				<>
				<b className="text-success">{dokter_caption || "UNDF"}</b>
				<div>Jam Janjian: <b className="text-primary">{(jam_awal||"").substring(0,5)}</b>
				<span className="mx-1">s.d.</span>
				<b className="text-primary">{(jam_akhir||"").substring(0,5)}</b></div>
				</>
				) : (
				<span>No.Urut: <b className="text-success">{urutan||1}</b></span>
				)}
			</CCol>
			{(keterangan||"")!=="" && (
			<>
			<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_keterangan||"Ket.Antri"}</CCol>
			<CCol xs="9" className="px-1 d-md-none fst-italic">{keterangan}</CCol>
			</>
			)}

			{vJenisantrian!=="UMUM" ? (
			<>
			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
			<CCol xs="8" className="px-1 d-md-none text-success">{dokter_caption||"UNDF"}</CCol>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_noruang||"No.Ruang"}</CCol>
			<CCol xs="8" className="px-1 d-md-none text-primary">Ruang {no_ruang||1}</CCol>

			<CCol xs="4" className="px-1 d-md-none text-start">Jam Janjian</CCol>
			<CCol xs="8" className="px-1 d-md-none">
				<b className="text-primary">{(jam_awal||"").substring(0,5)}</b>
				<span className="mx-1">s.d.</span>
				<b className="text-primary">{(jam_akhir||"").substring(0,5)}</b>
			</CCol>
			</>
			) : (
			<>
			<CCol xs="4" className="px-1 d-md-none text-start">No.Urut</CCol>
			<CCol xs="8" className="px-1 d-md-none fw-bolder">
				Ke-<span className="text-success">{urutan||1}</span></CCol>
			</>
			)}

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_inputby||"Inputby"}</CCol>
			<CCol xs="8" className="px-1 d-md-none classfontsmaller text-danger">
				<small>[{vTglInputcaption}{userinput||"UNDF"}]</small>
			</CCol>

			<CCol md="2" className="px-1 d-none d-md-block text-md-center">
				<CLink className="classcursorpointer classikontabel classikonedit"
					onClick={()=>hdlKlikEdit(id)}/>
				<CLink className="classcursorpointer classikontabel classikonhapus"
					onClick={()=>hdlKlikHapus(id)}/>
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//->
	}
	const contentCabang = () => {
		if(uHtml500msg) return ""

		if(uTokenObj.userhak!=="FREEROLE" && uTokenObj.userhak!=="ADMIN") {
		//if(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") {
			const vArridx	= UFunc.getObjectIndeks(uDatacabangArr,uTokenObj.user_cabangid);

			return (
			<>&middot; <i style={{color:"Blue"}}>{uDatacabangArr[vArridx].cabang_nama}</i></>
			)
		}

		const vCabangIDallowedArr = uTokenObj.user_cabangarr || [];
		const vCabangfilterArr = uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=> vCabangIDallowedArr.includes(vItems.id.toString()));

		if(!isMobile) return (
		<CFormSelect size="sm"
			value={uInpcabangid}
			onChange={(e)=>{setInpcabangid(e.target.value);}}
			className="d-inline-block w-auto classfontsmaller py-1"
			id="inpcabangid">
		{vCabangfilterArr.map((vItems,vKeys)=>{
			return (
			<option key={vKeys} value={vItems.id}>{vItems.cabang_nama}</option>
			)
		})}
		</CFormSelect>
		)//>

		return(
		<>
		<CDropdown>
			<CDropdownToggle color="light rounded border" className="py-0" caret={true}>{
			(()=>{
				const vArridx = UFunc.getObjectIndeks(vCabangfilterArr,uInpcabangid);
				return (vCabangfilterArr[vArridx]||{}).cabang_kode||"UNDF";
			})()
			}</CDropdownToggle>
			<CDropdownMenu>
			{vCabangfilterArr.map((vItems,vKeys)=>{
				return (
				<CDropdownItem onClick={()=>setInpcabangid(vItems.id)}
					key={vKeys}>
					{vItems.cabang_kode}
					<span className="mx-2">&middot;</span>
					{vItems.cabang_nama}
				</CDropdownItem>
				)
			})}
			</CDropdownMenu>
		</CDropdown>
		</>
		)//->
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//->

		if(uDlgformjenis === "ANTRIAN") return contentFormantrian();
		if(uDlgformjenis === "EDITPASIEN") return contentFormeditpasien();

		return (<></>)//>
	}
	const contentFormantrian = () => {
		if(uDlgformjenis !== "ANTRIAN") return (<></>);//>

		const vArridx		= UFunc.getObjectIndeks(uDatacabangArr,uInpcabangid);
		const vCabangcaption= uDatacabangArr[vArridx].cabang_nama||"UNDF"//-*/

		const vCabangIDallowedArr = uTokenObj.user_cabangarr || [];
		const vCabangfilterArr = uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=> vCabangIDallowedArr.includes(vItems.id.toString()));//-*/

		return (
		<CForm className="mx-lg-5">
		<CRow className="mx-1 mx-md-2 mb-3 py-1 py-md-2 border">
			<CCol className="classpetunjuk px-lg-5">*) {UFunc.renderHTML(uBahasaObj.petunjuk_daftarantri||"Masukkan Data-data Pendaftaran Pemeriksaan pada form di bawah ini, kemudian tekan SIMPAN.<br/>"+
			"<br/>"+
			"Untuk Antrian REGULER, KOSONGI Nama Dokter dan Jam Janjian,<br/>"+
			"Untuk Antrian JANJIAN, Nama Dokter dan Jam Janjian HARUS DIISI..")}</CCol>
		</CRow>

		{uDatacabangArr.length > 1 && (
		<CRow className="my-3">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_unitcabang||"Unit Cabang"}</CCol>
		<CCol xs="8" className="text-end text-md-start text-primary">
			<b className="">{vCabangcaption}</b>
			<CFormSelect size="sm"
				value={uInpformcabangid}
				onChange={(e)=>setInpformcabangid(e.target.value)}
				className="d-none"
				id="inpformcabangid">
			{vCabangfilterArr.map((vItems,vKeys)=>{
				return (
				<option key={vKeys} value={vItems.id}>{vItems.cabang_nama}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end fw-bold">{uBahasaObj.caption_tglantri||"Tgl Antri"}</CCol>
		<CCol xs="8" lg="4" className="text-end">
			<CFormInput size="sm" type="date"
				value={uInpTglantri}
				onChange={(e)=>setInpTglantri(e.target.value)}
				id="inptglantri"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end text-success">
			<small className="d-md-none">{uBahasaObj.caption_pilihdokter||"Dokter Janjian"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_pilihdokter||"Dokter Janjian"}</span>
		</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm" 
				value={uInpDoktercaption}
				onChange={(e)=>setInpDoktercaption(e.target.value)}
				onKeyDown={(e)=>hdlKDowndoktercaption(e)}
				id="inpdoktercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikLookupdokter()}
				id="btndokterpilih">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end text-success">
			<small className="d-md-none">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</span>
		</CCol>
		<CCol xs="8" lg="4" className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="time"
				min="07:00" max="22:00" 
				value={uInpJamawal}
				onChange={(e)=>setInpJamawal(e.target.value)}
				id="inpjamawal"/>
			<CInputGroupText className="py-0">s.d.</CInputGroupText>
			<CFormInput size="sm" type="time"
				min="07:00" max="22:00" 
				value={uInpJamakhir}
				onChange={(e)=>setInpJamakhir(e.target.value)}
				id="inpjamakhir"/>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end text-success">
			<small className="d-md-none">{uBahasaObj.caption_pilihruang||"Pilih Ruangan"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_pilihruang||"Pilih Ruangan"}</span>
		</CCol>
		<CCol xs="8" lg="4" className="text-end">
			<CFormSelect size="sm"
				value={uInpNoruang}
				onChange={(e)=>setInpNoruang(e.target.value)}
				id="inpnoruang">
				{(() => {
					 let vOption = [];
					 for (let i = 1; i <= (parseInt(uCabanginitObj.jml_ruangan)||2); i++) {
					 	vOption.push(<option value={i} key={i}>Ruang No-{i}</option>);
					 }
					 return vOption;
				})()}
			</CFormSelect>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_ketantrian||"Ket.Antrian"}</CCol>
		<CCol className="text-end">
			<CFormInput size="sm" maxLength={255}
				value={uInpKeterangan}
				onChange={(e)=>setInpKeterangan(e.target.value)}
				onKeyDown={(e)=>{
					const vElkehadiran = document.getElementById("inpstatushadir")
					if(vElkehadiran.disabled===true || (vElkehadiran.offsetParent === null))
						UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
				}}
				id="inpketantri"/>
		</CCol>
		</CRow>

		<CRow className={"my-2"+((parseInt(uIDselect)||0) > 0 ? " d-none" :"")}>
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_statushadir||"Kehadiran"}</CCol>
		<CCol xs="8" lg="4" className="text-end">
			<CFormSelect size="sm"
				value={uInpKehadiran}
				disabled={true}
				onChange={(e)=>setInpKehadiran(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpstatushadir">
			<option value="BELUM HADIR">BELUM HADIR</option>
			<option value="HADIR">HADIR</option>
			</CFormSelect>
		</CCol>
		</CRow>
		<div className="d-none">{JSON.stringify(uCabanginitObj)}</div>
		</CForm>
		)
	}
	const contentFormeditpasien = () => {
		if(uDlgformjenis !== "EDITPASIEN") return (<></>);//>
	
		return (
			<MyCardformpasien 
				pasienID={uDlgformPasienID}
				showButton={false}
				simpanKlik={uDlgPasiensimpan}
				{...props}/>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();

		setDatadetilObj({})
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Daftarantri - apiLoadawal) LEWAT ");
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_formantrian";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatadetilObj(JSON.parse(output_string.datapasien||"{}"))
				setDatadokterArr(JSON.parse(output_string.datadokter||"[]"))
				setInpcabangid(parseInt(uTokenObj.user_cabangid||"0"));
				//apiLoadtabel();
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Daftarantri - apiLoadawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Daftarantri - apiLoadawal) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadtabel = () => {
		setDatatabelArr([])
		setJmlData(0);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Daftarantri - apiLoadtabel) LEWAT ");
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_antrian";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataantrian||"[]")

				setDatatabelArr(vTmpArr)
				setJmlData(vTmpArr.length);

				const vTmpObj 	= JSON.parse(output_string.cabangatribut || JSON.stringify(uCabangselectedObj));
				setCabanginitObj(vTmpObj);
				//setDataconfigObj(JSON.parse(output_string.dataconfig||"{}"))

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Daftarantri - apiLoadtabel) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Daftarantri - apiLoadtabel) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL < 0) return;

		const vEltombol	= document.getElementById("btnDialogSimpan");
		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL)
		vEltombol && (vEltombol.disabled = true);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_pasienid : uPasienID,
			send_cabangid: uInpformcabangid,
			send_tglantri: uInpTglantri,
			send_dokterid: uInpDokterid,
			send_jamawal: uInpJamawal,
			send_jamakhir: uInpJamakhir,
			send_noruang: uInpNoruang,
			send_keterangan: uInpKeterangan,
			send_kehadiran: uInpKehadiran,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_simpan";

		//showToast(vDATAS);vEltombol && (vEltombol.disabled = false); setLoading(false); return;
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vEltombol && (vEltombol.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				const vTabelid	= output_string.tabelid || _IDTABEL;
				const vNourut	= output_string.nourut || 255;
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id:vTabelid,
						tgl_antri:uInpTglantri,
						dokter_id:uInpDokterid,dokter_caption:uInpDoktercaption,
						urutan:vNourut,
						jam_awal:uInpJamawal+":00",
						jam_akhir:uInpJamakhir+":00",
						no_ruang:uInpNoruang,
						keterangan:uInpKeterangan,
						status_hadir:uInpKehadiran,
						tgl_input:"",
						userinput:uTokenObj.usercaption
					}

					setDatatabelArr([vTmpObj,...uDatatabelArr]);
					setJmlData(uJmlData+1);
					showToast((uBahasaObj.pesan_berhasil||"Berhasil, Terimakasih.."),"SUKSES")
				} else {
					const vArridx = UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL);
					uDatatabelArr[vArridx].tgl_antri = uInpTglantri;
					uDatatabelArr[vArridx].dokter_id = uInpDokterid;
					uDatatabelArr[vArridx].dokter_caption = uInpDoktercaption;
					uDatatabelArr[vArridx].urutan = vNourut;
					uDatatabelArr[vArridx].jam_awal = uInpJamawal;
					uDatatabelArr[vArridx].jam_akhir = uInpJamakhir;
					uDatatabelArr[vArridx].keterangan = uInpKeterangan;
					uDatatabelArr[vArridx].no_ruang = uInpNoruang;
					uDatatabelArr[vArridx].status_hadir = uInpKehadiran;
				}
				hdlToggleDlgform();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Daftarantri - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEltombol && (vEltombol.disabled = false);
			setLoading(false);
			console.log("(Daftarantri - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatatabelArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangid : uInpcabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatatabelArr.splice(vArridx,1);
				setRowselect(-1);
				setJmlData(uJmlData-1);
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoadawal();

    	return () => {
			setDlgformshow(false);
    		setDatadetilObj({});
    		setDatatabelArr([]);
    		setDataDlglookupArr([]);
    		setDatadokterArr([]);
			setCabanginitObj(uCabangselectedObj);
			initDlgformawal();
    	}
	},[])
	React.useEffect(()=>{ 
		if(uInpcabangid <= 0) return;
		apiLoadtabel();
	},[uInpcabangid])
	React.useEffect(()=>{ 
		if(!uIsDlgformshow) { initDlgformawal(); return; }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inptglantri");
			vElfocus && vElfocus.focus();
			initDlgformedit(uIDselect);
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		//-->Action untuk status kehadiran, jika Tgl Antri Bukan Hari Ini
		const vTglantriDT	= UFunc.toDate(uInpTglantri);
		const vTglTodayDT 	= UFunc.DbDate();
		const vElkehadiran	= document.getElementById("inpstatushadir");

		if(UFunc.TglAngka(vTglantriDT) !== UFunc.TglAngka(vTglTodayDT)
			|| ((parseInt(uInpDokterid)||0) <= 0)
		) {
			if(parseInt(uIDselect) <= 0) setInpKehadiran("BELUM HADIR");
			vElkehadiran && (vElkehadiran.disabled = true);
			vElkehadiran && (vElkehadiran.classList.add("classbgdisabled"));
		} else {
			vElkehadiran && (vElkehadiran.disabled = false);
			vElkehadiran && (vElkehadiran.classList.remove("classbgdisabled"));
		}
	},[uInpTglantri,uInpDokterid])
	React.useEffect(()=>{
		const vElfocus = document.getElementById("inpdoktercaption");
		if(!uIsDlglookupshow) {
			if(parseInt(uInpDokterid) <= 0) vElfocus && vElfocus.focus()
			return;
		} 
	},[uIsDlglookupshow])
	React.useEffect(()=>{
		if(uDlgPasiensimpan) {
			setDlgPasiensimpan(false);
			return 
		}
	},[uDlgPasiensimpan])
	React.useEffect(()=>{
		if(uDlgPasiendone) {
			setDlgformshow(false);
			uDispatch({type: "set", gInitprosespasien: false});
		}
	},[uDlgPasiendone]) 
	//---END EFFECT--/

	//console.log("(Daftarantri) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderBrand className="me-auto">
				<small className="fw-bold">{uBahasaObj.caption_reservasipasien||"Reservasi Pasien"}</small>
			</CHeaderBrand>

			<CHeaderNav>
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white p-2 px-md-3" style={{minHeight:52}}>
			{contentSubheader()}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-2">
		<CContainer fluid className="px-0">
			<CRow className="mx-lg-5">
			<CCol className="">
			<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
				{(!uHtml500msg) ? (
				<div>
					<span>Data Reservasi</span>
					<span className="mx-1">&middot;</span>
					<span className="text-primary">[<b>{uJmlData}</b>]</span>
				</div>
				) : (
					<b>Page 500</b>
				)}

				<div className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</div>
			</CCardHeader>

			<CCardBody className="px-0 px-lg-5">{contentData()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
		</CContainer>
		</div>
		</div>

		<MyDialogform
			options={{centered:true,size:(uDlgformjenis==="EDITPASIEN" ? "xl" : "lg")}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<MyDialogpasien show={uIsDlgpasienshow}
			pasienID={uDlgPasienID}
			toggle={hdlToggleDlgpasien}
			{...props}/>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlgLookup()}
			dialogHeader={uDlglookupheader||"Lookup Data"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>
		</>
	)//>
}	

export default Daftarantri