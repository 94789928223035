import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CButton,
	CImage,
	CLink,
	CWidgetStatsD,CWidgetStatsB,CWidgetStatsC,
	CCard,CCardHeader,CCardBody,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { CChart,CChartLine } from '@coreui/react-chartjs';
import { getStyle, hexToRgba } from '@coreui/utils';
import { isMobile } from "react-device-detect";
import {
	MyDialogview
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Dashboard = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const [uDatawidgetObj,setDatawidgetObj]	= React.useState({});
	const [uDatagrafikArr,setDatagrafikArr]	= React.useState([]);
	const [uLoadingObj,setLoadingObj]		= React.useState({});
	const [uMsgvarObj,setMsgvarObj]			= React.useState({});
	const [uISShowdokterfee,setShowdokterfee] 	= React.useState(false);
	//--END VIEWS_VARS--*/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	const uElcabang		= document.getElementById("inpcabangid")
	const uElperiode	= document.getElementById("inpperiode")

	const uTglnowDT		= UFunc.toDate()
	const uTglnowStr	= uTglnowDT.getFullYear()+"-"+UFunc.leadZero(uTglnowDT.getMonth()+1)+"-01";
	const uISMonthnotNOW	= ((uKeywordObj.filter_dashboard||{}).tgl_filter !== uTglnowStr)


	//--HANDEL--/
	const hdlKlikDetilBirthday = () => {
		if(uISDlgviewshow) { setDlgviewshow(false); return }
		const vDataArr	= uDatawidgetObj.birthday_arr || [];
		if(vDataArr.length <= 0) return;

		const vTglFilterDT = UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter);
		const vTglfilterNowDT = UFunc.toDate(
			vTglFilterDT.getFullYear()+"-"+
			UFunc.leadZero(vTglFilterDT.getMonth()+1)+"-"+
			UFunc.leadZero(uTglnowDT.getDate())
			);

		uDlgviewObj.size 	= "";
		uDlgviewObj.headers = "Pasien Berulang Tahun: "+UFunc.HariAngka(vTglfilterNowDT);
		setDlgviewshow(true);
	}
	//--END HANDEL--/

	//--INIT_FUNCTION--/
	const initResetdata	= () => {
		setHtml500msg();
		setDatagrafikArr([]);
		//setDatawidgetObj({});

		uDatawidgetObj.pasien = null
		uDatawidgetObj.user = null
		uDatawidgetObj.antrian = null
		uDatawidgetObj.omzet = null
		uDatawidgetObj.omzetgrafik = null
		uDatawidgetObj.dokter = null
		uDatawidgetObj.nurse = null
		uDatawidgetObj.terapi = null

		uMsgvarObj.pasien = null
		uMsgvarObj.user = null
		uMsgvarObj.antrian = null
		uMsgvarObj.omzet = null
		uMsgvarObj.omzetgrafik = null
		uMsgvarObj.dokter = null
		uMsgvarObj.nurse = null
		uMsgvarObj.terapi = null

		uLoadingObj.pasien = false
		uLoadingObj.user = false
		uLoadingObj.antrian = false
		uLoadingObj.omzet = false
		uLoadingObj.omzetgrafik = false
		uLoadingObj.dokter = false
		uLoadingObj.nurse = false
		uLoadingObj.terapi = false
	}
	const initRespasien = (_OUTPUTOBJ) => {
		//setLoadingObj({...uLoadingObj,pasien:false})
		//console.log("(Dashboard - initRespasien) _OUTPUTOBJ : "+_OUTPUTOBJ);// return;
		uLoadingObj.pasien	= false;
		//uMsgvarObj.pasien	= _OUTPUTOBJ.info; return;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.pasienobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			//setMsgvarObj({...uMsgvarObj,pasien: _OUTPUTOBJ.info})
			uMsgvarObj.pasien = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRespasien) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,pasien: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.pasien = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResuser = (_OUTPUTOBJ) => {
		//setLoadingObj({...uLoadingObj,user:false})
		uLoadingObj.user	= false;
		//console.log("(Dashboard - initResuser) _OUTPUTOBJ : "+_OUTPUTOBJ);// return;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.userobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.user = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,user: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResuser) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,user: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.user = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResantrian = (_OUTPUTOBJ) => {
		uLoadingObj.antrian	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.antrianobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.antrian = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,antrian: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResantrian) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.antrian = "<font color='red'>"+vMsg+"</font>";
			//setMsgvarObj({...uMsgvarObj,antrian: "<font color='red'>"+vMsg+"</font>"})
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResomzet = (_OUTPUTOBJ) => {
		uLoadingObj.omzet = false;
		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.omzethariobj = JSON.parse(_OUTPUTOBJ.datahari||"{}");
			uDatawidgetObj.omzetbulanobj = JSON.parse(_OUTPUTOBJ.databulan||"{}");
		} else if(_OUTPUTOBJ.info) {
			//setMsgvarObj({...uMsgvarObj,omzet: _OUTPUTOBJ.info})
			uMsgvarObj.omzet = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResomzet) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,omzet: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.omzet = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResomzetgrafik = (_OUTPUTOBJ) => {
		uLoadingObj.omzetgrafik = false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.omzetgrafikarr = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.omzetgrafik = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,omzetgrafik: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResomzetgrafik) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.omzetgrafik = "<font color='red'>"+vMsg+"</font>";
			//setMsgvarObj({...uMsgvarObj,omzetgrafik: "<font color='red'>"+vMsg+"</font>"})
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResdokter = (_OUTPUTOBJ) => {
		uLoadingObj.dokter = false;
		
		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.dokterobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.dokter = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,dokter: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResdokter) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,dokter: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.dokter = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
		setEfekview(!uEfekview);
	}
	const initResnurse = (_OUTPUTOBJ) => {
		uLoadingObj.nurse = false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.nurseobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.nurse = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,nurse: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResnurse) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,nurse: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.nurse = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResterapi = (_OUTPUTOBJ) => {
		uLoadingObj.terapi = false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.terapiobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.terapi = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,terapi: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResterapi) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,terapi: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.terapi = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResbirthday = (_OUTPUTOBJ) => {
		uLoadingObj.birthday	= false;
		//uMsgvarObj.pasien	= _OUTPUTOBJ.info; return;

		if(_OUTPUTOBJ.tampil) {
			//console.log("_OUTPUTOBJ.datalist => "+_OUTPUTOBJ.datalist);
			uDatawidgetObj.birthday_arr = JSON.parse(_OUTPUTOBJ.datalist||"[]");
		} else if(_OUTPUTOBJ.info) {
			//setMsgvarObj({...uMsgvarObj,pasien: _OUTPUTOBJ.info})
			uMsgvarObj.birthday = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResbirthday) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.birthday = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	//--END INIT_FUNCTION--/

	//--CONTENT--/
	const contentSpinner = (_COLOR) => {
		_COLOR = _COLOR || "info";

		return (
			<div className="text-center p-5 border rounded-2 mb-3">
			<CSpinner color={_COLOR} className="mx-auto my-4"/>
			</div>
		)//>
	}
	const contentWidgetpasien = () => {
		if((uLoadingObj.pasien||false)===true) 
			return (<>{contentSpinner("primary")}</>)//->

		//console.log("(Dashboard - contentWidgetpasien) uMsgvarObj.pasien => "+uMsgvarObj.pasien)
		if(uMsgvarObj.pasien) return (
			<CCard className="classbgcard h-100">
			<CCardBody className="py-5 text-primary">
				<div className="my-3 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.pasien)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.pasienobj || {};
		//console.log("(Dashboard - contentWidgetpasien) vDataObj => "+JSON.stringify(vDataObj))
		//if(JSON.stringify(vDataObj)==="{}") return (<></>)

		return (
		<CWidgetStatsD
			icon={<CImage className="my-3 text-white" src={pjson.homepage+"api/images/menus_pasien.png"} height={52} />}
			values={[
				{ title: "Pasien per Bulan", value: UFunc.formatAngka(vDataObj.value) },
			]}
			style={{ "--cui-card-cap-bg": "#1085D6" }}
			className="h-100"/>
		)
	}
	const contentWidgetuser = () => {
		if((uLoadingObj.user||false)=== true) 
			return (<>{contentSpinner("success")}</>)

		if(uMsgvarObj.user) return (
			<CCard className="classbgcard h-100">
			<CCardBody className="py-5 text-success">
				<div className="my-3 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.user)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj		= uDatawidgetObj.userobj || {};

		return (
		<CWidgetStatsD
			icon={<CImage className="my-3 text-white" src={pjson.homepage+"api/images/menus_user.png"} height={52} />}
			values={[
				{ title: "User Online", value: vDataObj.value||0 },
			]}
			style={{
				"--cui-card-cap-bg": "#229954"+(uISMonthnotNOW?"33":""),
				"--cui-card-bg" : (uISMonthnotNOW?"#f0f0f088":"inherit"),
			}}
			onClick={()=>{ if(!uISMonthnotNOW) uNavigate("/subdasar/user")}}
			className={"classcursorpointer h-100"+(uISMonthnotNOW?" text-muted":"")}/>
		)//>
	}
	const contentWidgetantrian = () => {
		if((uLoadingObj.antrian||false)===true) 
			return (<>{contentSpinner("warning")}</>)//>

		if(uMsgvarObj.antrian) return (
			<CCard className="classbgcard h-100">
			<CCardBody className="py-5 text-warning">
				<div className="my-3 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.antrian)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj		= uDatawidgetObj.antrianobj || {};

		return (
		<CWidgetStatsD
			icon={<CImage className="my-3 text-white" src={pjson.homepage+"api/images/menus_antrianpasien.png"} height={52} />}
			values={[
				{ title: "Reservasi", value: vDataObj.total || 0 },
				{ title: "PROSES", value: vDataObj.selesai || 0 },
			]}
			style={{
				"--cui-card-cap-bg": "#FFB200"+(uISMonthnotNOW?"33":""),
				"--cui-card-bg" : (uISMonthnotNOW?"#f0f0f088":"inherit"),
			}}
			onClick={()=>{ if(!uISMonthnotNOW) uNavigate("/subantrian/statuspasien")}}
			className={"classcursorpointer h-100"+(uISMonthnotNOW?" text-muted":"")}/>
		)//>
	}
	const contentWidgetomzethari = () => {
		if((uLoadingObj.omzet||false)===true) 
			return (<>{contentSpinner("primary")}</>)

		if(uMsgvarObj.omzet) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="py-5 bg-primary text-white">
				<div className="my-3 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.omzet)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.omzethariobj || {};

		return (
			<CWidgetStatsB
				color="primary"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={"Rp"+UFunc.formatAngka(vDataObj.value)}
				title={uTokenObj.userhak==="DOKTER"?"Fee per Hari":"Omzet per Hari"}
				text={"Input Terakhir "+((vDataObj.tgl_lastinput||"")!==""?UFunc.TglAngka(vDataObj.tgl_lastinput):"UNDF")}
				className="mb-3"/>
		)//>
	}
	const contentWidgetomzetbulan = () => {
		if((uLoadingObj.omzet||false)===true) return (<></>)
		if(uMsgvarObj.omzet) return (<></>)

		const vDataObj	= uDatawidgetObj.omzetbulanobj || {};

		return (
			<CWidgetStatsB
				color="primary"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={"Rp"+UFunc.formatAngka(vDataObj.value)}
				title={uTokenObj.userhak==="DOKTER"?"Fee per Bulan":"Omzet per Bulan"}
				className="mb-3"/>
		)//>
	}
	const contentWidgetpasienhari = () => {//--TIDAK_JADI--/
		if((uLoadingObj.omzet||false)===true) 
			return (<>{contentSpinner("primary")}</>)

		if(uMsgvarObj.omzet) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="py-5 bg-info text-white">
				<div className="my-3 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.omzet)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.omzethariobj || {};

		return (
			<CWidgetStatsB
				color="info"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={UFunc.formatAngka(vDataObj.value)+" Pasien"}
				title="Pasien per Hari"
				text={"Input Terakhir "+((vDataObj.tgl_lastinput||"")!==""?UFunc.TglAngka(vDataObj.tgl_lastinput):"UNDF")}
				className="mb-3"/>
		)//>
	}
	const contentWidgetpasienbulan = () => {//--TIDAK_JADI--/
		if((uLoadingObj.omzet||false)===true) return (<></>)
		if(uMsgvarObj.omzet) return (<></>)

		const vDataObj	= uDatawidgetObj.omzetbulanobj || {};

		return (
			<CWidgetStatsB
				color="info"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={UFunc.formatAngka(vDataObj.value)+" Pasien"}
				title="Pasien per Bulan"
				className="mb-3"/>
		)//>
	}
	const contentWidgetdokter = () => {
		if((uLoadingObj.dokter||false)===true) 
			return (<>{contentSpinner("info")}</>)

		if(uMsgvarObj.dokter) return (
			<CCard className="classbgcard h-100">
			<CCardBody className="py-5" style={{backgroundColor:"#6495ED99"}}>
				<div className="my-2 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.dokter)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.dokterobj || {};

		return (
			<CWidgetStatsB
				color="info"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={UFunc.Usercaptionfilter((vDataObj.dokter_caption || "Dokter: UNDF"),3)}
				title="Fee Dokter Tertinggi"
				text={"Rp"+UFunc.formatAngka(vDataObj.value)}
				onClick={()=>uNavigate("/subklinik/lapfeedokter")}
				className="classcursorpointer h-100"/>
		)
	}
	const contentWidgetnurse = () => {
		if((uLoadingObj.nurse||false)===true) 
			return (<>{contentSpinner("secondary")}</>)

		if(uMsgvarObj.nurse) return (
			<CCard className="classbgcard h-100">
			<CCardBody className="py-5" style={{backgroundColor:"#DCDCDC99"}}>
				<div className="my-2 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.nurse)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.nurseobj || {};

		return (
			<CWidgetStatsB
				color="dark"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				text={UFunc.formatAngka(vDataObj.value)+" POIN"}
				title="POIN Nurse Tertinggi"
				value={UFunc.Usercaptionfilter((vDataObj.nurse_caption||"Nurse: UNDF"),3)}
				onClick={()=>uNavigate("/subklinik/lapperawat")}
				className="classcursorpointer h-100"/>
		)
	}
	const contentWidgetterapi = () => {
		if((uLoadingObj.terapi||false)===true) 
			return (<>{contentSpinner("danger")}</>)

		if(uMsgvarObj.terapi) return (
			<CCard className="classbgcard h-100">
			<CCardBody className="py-5" style={{backgroundColor:"#FFBF0099"}}>
				<div className="my-2 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.terapi)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.terapiobj || {};

		return (
			<CWidgetStatsB
				color="danger"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				text={"Rp"+UFunc.formatAngka(vDataObj.value)}
				title="Terapi Tertinggi"
				value={UFunc.Usercaptionfilter((vDataObj.terapi_caption || "Terapi: UNDF"),3)}
				onClick={()=>{
					if(uTokenObj.userhak==="DOKTER") return;
					uNavigate("/subklinik/lapterapi")
				}}
				className="classcursorpointer h-100"/>
		)
	}
	const contentGrafikomzet = () => {
		if((uLoadingObj.omzetgrafik||false)===true) return (
			<div className="text-center p-5">
			<CSpinner color="primary" className="mx-auto my-5"/>
			</div>
		)

		if(uMsgvarObj.omzetgrafik) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="py-5">
				<div className="my-5 py-4 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.omzetgrafik)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataArr	= uDatawidgetObj.omzetgrafikarr || [];

		let vLabelXArr	= [];
		let vValueArr	= [];
		let vRataArr	= [];
		let vSUMTotal	= 0;
		const vGrafikArr= vDataArr;

		vGrafikArr.map((vItems,vKeys)=>{
			vLabelXArr.push((vItems.tanggal))
			vValueArr.push(vItems.nilai)

			vSUMTotal += parseFloat(vItems.nilai||0)
		}).join("");

		const vRata2	= Math.floor(vSUMTotal/(vGrafikArr.length));
		for(var i=0; i<=vGrafikArr.length-1; i++) {
			vRataArr.push(vRata2);
		}

		/*vLabelXArr	= ["2023-02-01","2023-02-02","2023-02-03","2023-02-04","2023-02-05",
			"2023-02-06","2023-02-07","2023-02-08","2023-02-09","2023-02-01"];
		vValueArr	= [3000000,1000000,5000000,6000000,2000000,7000000,4000000,10000000,7000000,9000000];
		vRataArr 	= [5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000]
		//-*/

		return (
			<CChart type="line"
				className="mb-3"
				data={{
					labels: vLabelXArr,
					datasets: [
					{
						label: uBahasaObj.word_pendapatan||"Omzet",
						borderColor: getStyle('--cui-primary'),
						backgroundColor: hexToRgba(getStyle('--cui-primary'), 5),
						pointHoverBackgroundColor: getStyle('--cui-primary'),
			        	borderWidth: 2,
			        	fill: true,
						data: vValueArr,						
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vRataArr,
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(tooltipItem[0].label);
								},
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += "Rp"+UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: Math.ceil(250 / 5),
								max: 250,
								callback: (label, index, labels) => {
									return UFunc.formatAngka(label/1000000)+'M'
								},
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("labels = "+JSON.stringify(labels))
									//console.log("labels = "+labels)
									//return JSON.stringify(labels);//[index];
									return UFunc.TglAngka(vLabelXArr[index]).substr(0,5)
								},
							},
							grid: { drawOnChartArea: false }
						},
					},
				}}
			id="idgrafikomzet"/>
		)
	}
	const contentGrafikpasien = () => {//--TIDAK_JADI--/
		if((uLoadingObj.omzetgrafik||false)===true) return (
			<div className="text-center p-5">
			<CSpinner color="primary" className="mx-auto my-5"/>
			</div>
		)

		if(uMsgvarObj.omzetgrafik) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="py-5">
				<div className="my-5 py-4 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.omzetgrafik)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataArr	= uDatawidgetObj.omzetgrafikarr || [];

		let vLabelXArr	= [];
		let vValueArr	= [];
		let vRataArr	= [];
		let vSUMTotal	= 0;
		const vGrafikArr= vDataArr;

		vGrafikArr.map((vItems,vKeys)=>{
			vLabelXArr.push((vItems.tanggal))
			vValueArr.push(vItems.nilai)

			vSUMTotal += parseFloat(vItems.nilai||0)
		}).join("");

		const vRata2	= Math.floor(vSUMTotal/(vGrafikArr.length));
		for(var i=0; i<=vGrafikArr.length-1; i++) {
			vRataArr.push(vRata2);
		}

		/*vLabelXArr	= ["2023-02-01","2023-02-02","2023-02-03","2023-02-04","2023-02-05",
			"2023-02-06","2023-02-07","2023-02-08","2023-02-09","2023-02-01"];
		vValueArr	= [3000000,1000000,5000000,6000000,2000000,7000000,4000000,10000000,7000000,9000000];
		vRataArr 	= [5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000]
		//-*/

		return (
			<CChart type="line"
				className="mb-3"
				data={{
					labels: vLabelXArr,
					datasets: [
					{
						label: uBahasaObj.word_jumlah||"Jumlah",
						borderColor: getStyle('--cui-info'),
						backgroundColor: hexToRgba(getStyle('--cui-info'), 5),
						pointHoverBackgroundColor: getStyle('--cui-info'),
			        	borderWidth: 2,
			        	fill: true,
						data: vValueArr,						
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vRataArr,
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(tooltipItem[0].label);
								},
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: Math.ceil(250 / 5),
								max: 250,
								callback: (label, index, labels) => {
									//return UFunc.formatAngka(label/1000000)+'M'
								},
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("labels = "+JSON.stringify(labels))
									//console.log("labels = "+labels)
									//return JSON.stringify(labels);//[index];
									return UFunc.TglAngka(vLabelXArr[index]).substr(0,5)
								},
							},
							grid: { drawOnChartArea: false }
						},
					},
				}}
			id="idgrafikomzet"/>
		)
	}
	const contentWidgetbirthday = () => {
		if((uLoadingObj.birthday||false)===true) 
			return (<div className="my-5 text-center h-100"><CSpinner color="danger"/></div>)

		if(uMsgvarObj.birthday) return (
			<div className="py-5 h-100">
				<div className="my-2 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.birthday)}</small>
				</div>
			</div>
		)

		const vDataArr	= uDatawidgetObj.birthday_arr || [];
		//console.log("vDataArr => "+JSON.stringify(vDataArr));

		return (
		<table className="table table-borderless table-hover">
		<thead>
			<tr className="classfontsmaller">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
			<th width="20%" className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataArr.filter((vItems,vKeys) => vKeys < 5 ).map((vItems,vKeys)=>{
			const {
				nama_pasien,umur,sex,
				id,
			} = vItems;

			return (
			<tr key={vKeys} className="classborderbottom">
				<td className="text-start">{(()=>{
					if(sex === "L")
					return (
						<CImage src={pjson.homepage+"api/images/icon_male.png"} height={25}/>
					);

					return (
						<CImage src={pjson.homepage+"api/images/icon_female.png"} height={25}/>
					);
				})()}</td>
				<td className="text-start">{nama_pasien||"Undf"}</td>
				<td className="text-end classfontsmaller"><b>{UFunc.formatAngka(umur)}</b> th</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)

		const vDataArr	= uDatawidgetObj.birthday_arr || [];
		return (
		<table className="table table-borderless table-hover">
		<thead>
			<tr className="classfontsmaller align-top">
			<th width={30} className="">&middot;</th>
			<th className="text-start">Nama Pasien</th>
			<th width="15%" className="text-end">Umur</th>
			</tr>
		</thead>

		<tbody>
		{vDataArr.map((vItems,vKeys)=>{
			const {
				nama_pasien,umur,sex,cabang_caption,telp_hp,
				id,
			} = vItems;

			return (
			<tr key={vKeys} className="classborderbottom">
				<td className="text-start">{(()=>{
					if(sex === "L")
					return (
						<CImage src={pjson.homepage+"api/images/icon_male.png"} height={25}/>
					);

					return (
						<CImage src={pjson.homepage+"api/images/icon_female.png"} height={25}/>
					);
				})()}</td>

				<td className="text-start">
					{nama_pasien||"Undf"}
					<div className="classfontsmaller">
					{(telp_hp||"")!=="" && (
						<small className="text-success">Telp/HP: <b>{telp_hp}</b></small>
					)}

					{((telp_hp||"")!=="" && uDatacabangArr.length > 1 ) && (
						<span className="mx-1">&middot;</span>
					)}

					{uDatacabangArr.length > 1 && (
					<>
						<small>{cabang_caption}</small>
					</>
					)}
					</div>
				</td>

				<td className="text-end classfontsmaller"><b>{UFunc.formatAngka(umur)}</b> th</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = () => {
		initResetdata();
		uLoadingObj.pasien = true
		uLoadingObj.user = true
		uLoadingObj.antrian = true
		uLoadingObj.omzet = true
		uLoadingObj.omzetgrafik = true
		uLoadingObj.dokter = true
		uLoadingObj.nurse = true
		uLoadingObj.terapi = true;
		uLoadingObj.birthday = true;

		uElcabang && (uElcabang.disabled = true)
		uElperiode && (uElperiode.disabled = true)
		setEfekview(!uEfekview);

		/*const vTimeout = setTimeout(async()=>{
			uElcabang && (uElcabang.disabled = false)
			uElperiode && (uElperiode.disabled = false)
			clearTimeout(vTimeout)

			await initRespasien({});
			await initResuser({});
			await initResantrian({});
			await initResomzet({});
			await initResomzetgrafik({});
			await initResdokter({});
			await initResnurse({});
			await initResterapi({});
			//uLoadingObj.terapi = false; setEfekview2(!uEfekview2);
			//setEfekview(!uEfekview);
		},2000); return//-*/

		let vFilterObj = (uKeywordObj.filter_dashboard||{});
		if(JSON.stringify(vFilterObj)==="{}") {
			const vTglNow	= new Date();
			vFilterObj = {
				id_cabang: localStorage.getItem("lastcabangid"),
				tgl_filter: UFunc.DbDate(new Date(vTglNow.getFullYear(),
					vTglNow.getMonth(),1)),
			}
		}
		const vDATAS    = JSON.stringify({
			send_datalist: JSON.stringify(vFilterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs     = pjson.homepage+"api/api_dashboard/";
		const vHeader	= {
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}

		let vUrlArr = [
			vURLs+"ld_pasien",
			vURLs+"ld_user",
			vURLs+"ld_antrian",
			vURLs+"ld_omzet",
			vURLs+"ld_omzetgrafik",
			vURLs+"ld_birthday",
			//vURLs+"ld_dokter",
			//vURLs+"ld_nurse",
			//vURLs+"ld_terapi",
		];
		if(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN")
			vUrlArr = [
				vURLs+"ld_pasien",
				vURLs+"ld_user",
				vURLs+"ld_antrian",
				vURLs+"ld_omzet",
				vURLs+"ld_omzetgrafik",
				vURLs+"ld_dokter",
				vURLs+"ld_nurse",
				vURLs+"ld_terapi",
			];
		else if(uTokenObj.userhak === "DOKTER")
			vUrlArr = [
				vURLs+"ld_pasien",
				vURLs+"ld_dokterfee",
				vURLs+"ld_doktergrafik",//"ld_omzetgrafik",
				vURLs+"ld_terapi",
				vURLs+"ld_antrian",
			];

		Promise.all(vUrlArr.map(url => fetch(url,vHeader).
			then(resp => { if (resp.status === 200){ setSessionaktif(false); return resp.json(); }})
		)).then(async(vResponArr) => {
			setSessionaktif(true);

			if(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") {
				const res_pasien 	= (vResponArr[0]) ? vResponArr[0] : {};
				const res_user 		= (vResponArr[1]) ? vResponArr[1] : {};
				const res_antrian 	= (vResponArr[2]) ? vResponArr[2] : {};
				const res_omzet 	= (vResponArr[3]) ? vResponArr[3] : {};
				const res_omzetgrafik = (vResponArr[4]) ? vResponArr[4] : {};
				const res_dokter 	= (vResponArr[5]) ? vResponArr[5] : {};
				const res_nurse 	= (vResponArr[6]) ? vResponArr[6] : {};
				const res_terapi 	= (vResponArr[7]) ? vResponArr[7] : {};

				await initRespasien(res_pasien);
				await initResuser(res_user);
				await initResantrian(res_antrian);
				await initResomzet(res_omzet);
				await initResomzetgrafik(res_omzetgrafik);
				await initResdokter(res_dokter);
				await initResnurse(res_nurse);
				await initResterapi(res_terapi);
			} else if(uTokenObj.userhak === "DOKTER") {
				const res_pasien 	= (vResponArr[0]) ? vResponArr[0] : {};
				const res_omzet 	= (vResponArr[1]) ? vResponArr[1] : {};
				const res_omzetgrafik = (vResponArr[2]) ? vResponArr[2] : {};
				const res_terapi 	= (vResponArr[3]) ? vResponArr[3] : {};
				const res_antrian 	= (vResponArr[4]) ? vResponArr[4] : {};

				await initRespasien(res_pasien);
				await initResomzet(res_omzet);
				await initResomzetgrafik(res_omzetgrafik);
				await initResterapi(res_terapi);
				await initResantrian(res_antrian);
			} else {
				const res_pasien 	= (vResponArr[0]) ? vResponArr[0] : {};
				const res_user 		= (vResponArr[1]) ? vResponArr[1] : {};
				const res_antrian 	= (vResponArr[2]) ? vResponArr[2] : {};
				const res_omzet 	= (vResponArr[3]) ? vResponArr[3] : {};
				const res_omzetgrafik = (vResponArr[4]) ? vResponArr[4] : {};
				const res_birthday	= (vResponArr[5]) ? vResponArr[5] : (uDatawidgetObj.birthday_arr||[]);

				await initRespasien(res_pasien);
				await initResuser(res_user);
				await initResantrian(res_antrian);
				await initResomzet(res_omzet);
				await initResomzetgrafik(res_omzetgrafik);
				await initResbirthday(res_birthday);
			}

			setEfekview2(!uEfekview2);

			uElcabang && (uElcabang.disabled = false)
			uElperiode && (uElperiode.disabled = false)
		}).catch((error1)=>{
			uElcabang && (uElcabang.disabled = false)
			uElperiode && (uElperiode.disabled = false)
			setLoadingObj({})

			if(error1) console.log("(Dashboard - apiLoaddata) catch-error: "+error1);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiLoaddata();

		return () => { 
			setDlgviewshow(false);
			uDispatch({type: "set", gInitHeaderAction: {}});
			setHtml500msg();
			setDatagrafikArr([]);
			setDatawidgetObj({});
			setLoadingObj({});
			setMsgvarObj({});

			/*//--TESTING--/
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			//--END TESTING--*/
		}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uISDlgviewshow) {
			setDlgviewObj({}); return
		}
	},[uISDlgviewshow])
	
	//console.log("(Dashboard) uLoadingObj => "+JSON.stringify(uLoadingObj))
	//console.log("Dashboard - uActiveroute => "+(uActiveroute));
	
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard>
		<CCardHeader className="d-flex justify-content-between">
			<b>{uBahasaObj.caption_page500||"Page 500"}</b>
			<CLink className="classcursorpointer classikon classikonreset"
				onClick={()=>apiLoaddata()}/>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)//>

	if(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") 
	return (
	<>
		<div className="mb-3 d-none">{JSON.stringify(uKeywordObj)}</div>
		<CRow className="align-items-stretch">
		<CCol xs="6" md="4" className="pb-3">{contentWidgetpasien()}</CCol>
		<CCol xs="6" md="4" className="pb-3">{contentWidgetuser()}</CCol>
		<CCol md="4" className="pb-3">{contentWidgetantrian()}</CCol>
		</CRow>

		<CRow className="">
		<CCol>
		<CCard className="mb-3 classbgcard mx-0">
		<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Pendapatan</small>
			<small className="text-muted classfontsmaller">
				<CLink
					onClick={()=>uNavigate("/suboperasional/laplabarugi")}
					className="classcursorpointer text-decoration-none">
				Lihat Detil
				<CIcon icon="cilArrowRight" className="align-middle"/>
				</CLink>
			</small>
		</CCardHeader>
		<CCardBody>
			<CRow className="mx-0">
			<CCol md="7" lg="8">{contentGrafikomzet()}</CCol>
			<CCol>
				{contentWidgetomzethari()}
				{contentWidgetomzetbulan()}
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<CRow className="align-items-stretch">
		<CCol md="4" className="pb-3">{contentWidgetdokter()}</CCol>
		<CCol md="4" className="pb-3">{contentWidgetnurse()}</CCol>
		<CCol md="4" className="pb-3">{contentWidgetterapi()}</CCol>
		</CRow>
	</>
	)//>

	if(uTokenObj.userhak === "DOKTER") return (
	<>
		<CRow className="align-items-stretch">
		<CCol xs="6" md="4" className="pb-3">{contentWidgetpasien()}</CCol>
		<CCol xs="6" md="4" className="pb-3">{contentWidgetterapi()}</CCol>
		<CCol md="4" className="pb-3">{contentWidgetantrian()}</CCol>
		</CRow>

		<CCard className="mb-3 classbgcard d-none">
		<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Jumlah Pasien</small>
		</CCardHeader>

		<CCardBody>
		<CRow>
		<CCol>
			{contentWidgetpasienhari()}
			{contentWidgetpasienbulan()}
		</CCol>

		<CCol md="7" lg="8">{contentGrafikpasien()}</CCol>
		</CRow>
		</CCardBody>
		</CCard>

		<CCard className="mb-3 classbgcard">
		<CCardHeader className={"d-flex justify-content-between"}>
			<small className="fw-bolder">Fee Dokter</small>
			<small className="text-muted classfontsmaller">
				<CLink onClick={()=>{setShowdokterfee(!uISShowdokterfee)}} className="classcursorpointer">
				<CIcon icon={!uISShowdokterfee ? "cilChevronBottom" : "cilChevronTop"} className="classikonbox" height={20}/>
				</CLink>
				<span className="mx-1">&middot;</span>
				<CLink
					onClick={()=>uNavigate("/subklinik/lapfeedokter")}
					className="classcursorpointer text-decoration-none">
				Lihat Detil
				<CIcon icon="cilArrowRight" className="align-middle"/>
				</CLink>
			</small>
		</CCardHeader>

		<CCardBody>
		{(uISShowdokterfee) ? (
			<CRow>
			<CCol md="7" lg="8">{contentGrafikomzet()}</CCol>

			<CCol>
				{contentWidgetomzethari()}
				{contentWidgetomzetbulan()}
			</CCol>
			</CRow>
		) : (
			<div className="text-muted fst-italic">
			&middot;&middot; Click TOGGLE Button to Show The Details.. &middot;&middot;
			</div>
		)}
		</CCardBody>
		</CCard>
	</>
	)

	return (
	<>
		<CRow className="align-items-stretch">
		<CCol xs="6" md="4" className="pb-3">{contentWidgetpasien()}</CCol>
		<CCol xs="6" md="4" className="pb-3">{contentWidgetuser()}</CCol>
		<CCol md="4" className="pb-3">{contentWidgetantrian()}</CCol>
		</CRow>

		<CCard className="mb-3 classbgcard">
		<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Penerimaan Kasir</small>
			<small className="text-muted classfontsmaller">
				<CLink
					onClick={()=>uNavigate("/suboperasional/lapkasir")}
					className="classcursorpointer text-decoration-none">
				Lihat Detil
				<CIcon icon="cilArrowRight" className="align-middle"/>
				</CLink>
			</small>
		</CCardHeader>
		<CCardBody>

		<CRow className="">
		<CCol md="7" lg="8">{contentGrafikomzet()}</CCol>

		<CCol>
			{contentWidgetomzethari()}
			{contentWidgetomzetbulan()}
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>

		<CRow>
		<CCol md="6">
		<CCard className={"mb-3 classbgcard"+(uISMonthnotNOW?" text-muted":"")}>
		<CCardHeader className="d-flex justify-content-between">
			<small className="fw-bolder">Pasien Berulang Tahun Hari Ini</small>
			<small className="text-muted classfontsmaller">
				<Link onClick={()=>hdlKlikDetilBirthday()}
					className="classcursorpointer text-decoration-none">
					LihatDetil
					<CIcon icon="cilArrowRight" className="align-middle"/>
				</Link>
			</small>
		</CCardHeader>

		<CCardBody style={{backgroundColor:(uISMonthnotNOW?"#f0f0f055":"inherit")}}
			className="">{contentWidgetbirthday()}</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<MyDialogview
			options={{size:uDlgviewObj.size,centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.headers}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}
			{...props}/>
	</>
	)
}	

export default Dashboard