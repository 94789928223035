import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CNavLink,
	CNavItem,
	CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ContHeaderProfile,ContHeaderSub } from './index'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json');
const ContHeader = (props) => {
	const { setToken,setLoading, showToast } = props 

	const uDispatch 	= useDispatch()
	const uNavigate 	= useNavigate()
	const uSidebarshow	= useSelector((state) => state.sidebarShow)
	const uActiveroute  = useSelector(state => state.activeRoute);
	const uBahasaObj  	= useSelector(state => state.listBahasa);
	const uTokenobj		= JSON.parse(localStorage.getItem("token")||"{}");
	const uDatacabangArr= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uCabangid		= parseInt(localStorage.getItem("lastcabangid")||"0");
	const uConfigappsObj= useSelector(state => state.uConfigappsObj) || {}; 
	const uCabangattrObj= useSelector(state => state.gCabangatribut) || {}; 
	const [uEfekview,setEfekview] = React.useState(uSidebarshow);
	const [uCabangcaption,setCabangcaption] = React.useState("UNDF");

	const uHeadercaption = (uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"")===""
		? pjson.name : uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()];

	React.useEffect(()=>{
		if(uDatacabangArr <= 0) return;
		const vObjidx	= UFunc.getObjectIndeks(uDatacabangArr,uCabangid);
		setCabangcaption(uDatacabangArr[vObjidx].cabang_nama)
	},[uCabangid])

	return (
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler
				onClick={() => {
					uDispatch({ type: 'set', sidebarShow: !uSidebarshow });
				}} className="px-0 align-middle">
			<CIcon icon="cilMenu" className="me-2 align-middle" height={25}/>
			</CHeaderToggler>

			{(uActiveroute||"").toUpperCase() === "DASHBOARD" ? (
			<CHeaderBrand className="d-md-none me-auto text-normal" onClick={()=>uNavigate("/dashboard")}>
				<span className="classfontbrand fs-2">
					{(pjson.name.split("nta"))[0]}
					<span>n</span>
					<CImage src="../images/icon_logo_t.png" height={35}/>
					<span>a</span>
					{(pjson.name.split("nta"))[1]}
				</span>
			</CHeaderBrand>
			) : (
			<CHeaderNav className="d-md-none me-auto text-normal" onClick={()=>uNavigate("/dashboard")}>
				<b>{uHeadercaption}</b>
			</CHeaderNav>
			)}
			
			<CHeaderNav className="d-none d-md-block me-auto text-normal classcursorpointer"
				onClick={()=>uNavigate("/dashboard")}>
				<b className="d-md-none d-lg-block">Hi, {UFunc.Usercaptionfilter(uTokenobj.usercaption)}</b>
				<b className="d-lg-none">{uHeadercaption}</b>
			</CHeaderNav>

			<CHeaderNav className="me-2 d-none d-md-block text-center">
				<div className="classfontoswald classfontcolormain text-capitalize">
				&middot;{(()=>{
					if(uDatacabangArr.length <= 1) return (<>{uCabangattrObj.nama_klinik}</>);

					return (<>{(uCabangattrObj.nama||"").toLowerCase()}</>);
				})()}&middot;
				</div>
			</CHeaderNav>

			<CHeaderNav className="">
				<ContHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<ContHeaderSub {...props}/>
		</CHeader>
	)//>
}

export default ContHeader

	