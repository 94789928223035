import React from 'react';
import { useSelector } from 'react-redux';
import {
	CContainer,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types';
//import { MyButton } from './index';
import { UFunc } from "../helpers/functions";

const MyDialogviewfullscreen = props => {
	const { 
		show, toggle, renderContent, dialogHeader, options, ...rest 
	} = props;

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uInitBgimage = (options||{}).init_bgimage ? options.init_bgimage : MyDialogviewfullscreen.defaultProps.options.init_bgimage;
	
	React.useEffect(()=>{
		if(!show) return;

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setEfekview(!uEfekview);
		},350);//-*/
	},[show]);

	//console.log("(MyDialogviewfullscreen) uInitBgimage = "+uInitBgimage);
	return (
		<>
		<CModal
			scrollable={true} 
			fullscreen={true} 
			visible={show}>
		<CModalHeader closeButton={false} style={{zIndex:1}}
			className="justify-content-start align-items-center classheadertop">
			<CLink onClick={toggle} className="classcursorpointer">
			<CIcon icon="cilArrowCircleLeft" className="me-2 text-black align-top" height={30}/>
			</CLink>

			<CModalTitle className="">
			<small className="fw-bolder">{UFunc.renderHTML(dialogHeader||MyDialogviewfullscreen.defaultProps.dialogHeader)}</small>
			</CModalTitle>
		</CModalHeader>

		<CModalBody style={{
			zIndex:1,
			backgroundColor:(options||{}).hasOwnProperty("bgColor")?options.bgColor:MyDialogviewfullscreen.defaultProps.options.bgColor,
			}} className="">
			{renderContent || MyDialogviewfullscreen.defaultProps.renderContent}
		</CModalBody>

		{(uInitBgimage||"YA")==="YA" && (
			<div style={{
				opacity:0.2,background:"white url(/images/bg_gradient_bottom.png) bottom right no-repeat",
			}} 
			className="position-fixed w-100 h-100 top-0 start-0"/>
		)}
		</CModal>
		</>
	)//>
}
MyDialogviewfullscreen.defaultProps = {
	show: false,
	renderContent: "{UNDEFINED}..",
	dialogHeader: "View Data",
	options: {bgColor:"transparent", init_bgimage: "YA"}
};

MyDialogviewfullscreen.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	renderContent: PropTypes.element, 
	dialogHeader: PropTypes.string,
	options: PropTypes.object,
}

export default MyDialogviewfullscreen;
