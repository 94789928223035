import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CFormTextarea,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
import useSatusehat from '../helpers/useSatusehat'

const pjson 	= require('../../package.json')
const Dokter 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const { loadToken } 		= useSatusehat(props);
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uTokensatusehatObj		= JSON.parse(localStorage.getItem("tokensatusehat")||"{}");

	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") && !uISDemoapp;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDatauserArr,setDatauserArr]		= React.useState([]);
	const [uDlgformdataObj,setDlgformdataObj]= React.useState({});

	/*const [uDlgformdataObj.pengguna_id,uDlgformdataObj.pengguna_id = ]			= React.useState(0);
	const [uDlgformdataObj.persen_pendapatan,uDlgformdataObj.persen_pendapatan = ]			= React.useState(0);
	const [uDlgformdataObj.persen_bunga_cicilan,uDlgformdataObj.persen_bunga_cicilan = ]	= React.useState(0);
	const [uDlgformdataObj.nama_dokter,uDlgformdataObj.nama_dokter = ] = React.useState("");
	const [uDlgformdataObj.alamat_dokter,uDlgformdataObj.alamat_dokter = ]			= React.useState("");
	const [uDlgformdataObj.spesialisasi,uDlgformdataObj.spesialisasi = ]	= React.useState("");
	const [uDlgformdataObj.dlg_header,uDlgformdataObj.dlg_header = ]			= React.useState("");//-*/
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlg = () =>{ setDlgformshow(false) }

	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].nama_dokter||"").toUpperCase();
		setRowselect(vIdxRow);

		uDlgformdataObj.nama_dokter 	= (uDatamainArr[vIdxObj].nama_dokter||"");
		uDlgformdataObj.alamat_dokter 	= (uDatamainArr[vIdxObj].alamat_dokter);
		uDlgformdataObj.spesialisasi 	= (uDatamainArr[vIdxObj].spesialisasi||"");
		uDlgformdataObj.persen_pendapatan = (uDatamainArr[vIdxObj].persen_pendapatan||50);
		uDlgformdataObj.persen_bunga_cicilan = (uDatamainArr[vIdxObj].persen_bunga_cicilan||0);
		uDlgformdataObj.pengguna_id 	= (uDatamainArr[vIdxObj].dokter_userid||"0");

		uDlgformdataObj.ihs_number 		= uDatamainArr[vIdxObj].ihs_number || "";
		uDlgformdataObj.nik 			= uDatamainArr[vIdxObj].nik || "";

		uDlgformdataObj.dlg_header 		= "Edit Data: "+vCaptionUpper;
		apiLoaduser(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].nama_dokter||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikDlgsimpan = async() => {
		let vElFocus;
		vElFocus = document.getElementById("inpnamadokter");
		if(uDlgformdataObj.nama_dokter.trim()==="") {
			vElFocus.focus();
			showToast("Nama Dokter Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inppersen");
		if(parseInt(uDlgformdataObj.persen_pendapatan) < 0) {
			vElFocus.focus();
			showToast("Persen Fee Dokter Harus Tidak Valid !");return;
		}
		vElFocus = document.getElementById("inpuserid");
		if(parseInt(uDlgformdataObj.pengguna_id) <= 0) {
			vElFocus.focus();
			showToast("Username Login Harus Dipilih !");return;
		}

		if((uDlgformdataObj.nik||"")==="") {
			if(await Konfirm(
				"<b>NIK Dokter Tidak Diisi</b>.."+
				"<small class='mx-3 fst-italic'>"+
				"<br/>Jika NIK Tidak Diisi, Anda Tidak Akan Mendapatkan IHS (Indonesia Health Services) Number Dokter Tersebut."+
				"<br/><br/>Apabila IHS Number Tidak Ada, Data Pemeriksaan Oleh Dokter Tersebut Tidak Akan Dapat Dikirimkan ke SATUSEHAT Platform."+
				"</small>"+
				"<br/><br/>Apakah Anda Yakin ?"+
				"")) apiProsessimpan(uIDselect);

			return;
		}

		if(uIDselect > 0) {
			if((uDlgformdataObj.ihs_number||"")!=="") {
				apiProsessimpan(uIDselect); return;
			}
		}

		apiRegisterihs(uIDselect);
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(Dokter - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)

		uDlgformdataObj.pengguna_id 	= 0;
		uDlgformdataObj.persen_pendapatan = 50;
		uDlgformdataObj.persen_bunga_cicilan = 0;
		uDlgformdataObj.nama_dokter 	= "";
		uDlgformdataObj.alamat_dokter 	= "";
		uDlgformdataObj.spesialisasi 	= "";
		uDlgformdataObj.nik 			= "";
		uDlgformdataObj.ihs_number 		= "";
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_dokter || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
				<CCol md="3" className="text-start">{uBahasaObj.caption_namadokter||"Nama Dokter"}</CCol>
				<CCol md="2" className="text-start">{uBahasaObj.word_username||"Username"}</CCol>
				<CCol md="2" className="">{uBahasaObj.caption_ihsnumber||"IHS Number"}</CCol>
				<CCol md="1" className="">{uBahasaObj.caption_persenfee||"Persen Fee"}</CCol>
				<CCol md="2" lg="3" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
				<CCol md="2" lg="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
				<th width={30} className="p-"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,nama_dokter,dokter_username,alamat_dokter,
				spesialisasi,init_process,persen_pendapatan,persen_bunga_cicilan,ihs_number,nik
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (nama_dokter||"").toUpperCase();

			const vUsername	= vKeyword==="" ? (dokter_username||"") 
				: (dokter_username||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vDoktercaption	= vKeyword==="" ? "DRG."+(nama_dokter||"") 
				: "DRG."+(nama_dokter||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vSpesialis	= vKeyword==="" ? (spesialisasi||"") 
				: (spesialisasi||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vAlamat	= vKeyword==="" ? (alamat_dokter||"") 
				: (alamat_dokter||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vIHSnumber	= vKeyword==="" ? (ihs_number||"") 
				: (ihs_number||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vNIK	= vKeyword==="" ? (nik||"-") 
				: (nik||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<span className="text-capitalize text-info">{UFunc.renderHTML(vDoktercaption)}</span>
						
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm"
							className="p-0" 
							color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle me-2" height={25}/>{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							
							{(uIsHapusallow) && (
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle me-2" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
					</div>

					<span className="text-danger d-none d-md-block classfontsmaller">
						<small>[
						{UFunc.WaktuAngka(tgl_input||"")}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}
						]</small>
					</span>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_username||"Username"}</CCol>
				<CCol xs="8" md="2" className="px-1 text-md-start fw-bolder text-primary">
					&middot;{UFunc.renderHTML(vUsername)}&middot;
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_ihsnumber||"IHS Number"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-success text-md-center">
					{(ihs_number||"")==="" ? (
					<>
						<CIcon icon="cilXCircle" className="d-none d-md-inline-block" height={25}/>
						<i className="text-muted d-md-none">unavailable</i>
					</>
					) : (<b>{UFunc.renderHTML(vIHSnumber)}</b>)
					}
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_persenfee||"Persen Fee"}</CCol>
				<CCol xs="7" md="1" className="px-1 px-md-0 text-warning text-md-center fw-bolder">
					<small>{UFunc.formatAngka(persen_pendapatan)} %</small>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol md="2" lg="3" className="px-1 text-md-start d-none d-md-block">
					{((alamat_dokter||"")===""
						&&(nik||"")==="" 
						&&(spesialisasi||"")==="" 
						&& (parseFloat(persen_bunga_cicilan||"0")) <= 0) 
						? (<>-</>) : (
						<>
						{(nik||"")!=="" && (
						<div className="d-none d-md-block">
							{uBahasaObj.word_nik||"NIK"}:
							<b className="text-uppercase ms-1">{UFunc.renderHTML(vNIK)}</b>
						</div>
						)}

						{(parseFloat(persen_bunga_cicilan||"0")) > 0 && (
						<div className="d-none d-md-block">
							{uBahasaObj.caption_bungacicilan||"Bunga Cicilan"}:{" "}
							<b>{UFunc.formatAngka(persen_bunga_cicilan||0)} %</b>
						</div>
						)}

						{(alamat_dokter||"")!=="" && (
						<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
						)}

						{(spesialisasi||"")!=="" && (
						<div className="d-none d-md-block">
							{uBahasaObj.word_spesialisasi||"Spesialisasi"}:
							<b className="text-uppercase ms-1">{UFunc.renderHTML(vSpesialis)}</b>
						</div>
						)}
						</>
					)}
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_nik||"NIK"}</CCol>
				<CCol xs="8" className="px-1 d-md-none">
					<small className="">{UFunc.renderHTML(vNIK)}</small>
				</CCol>

				{(parseFloat(persen_bunga_cicilan||"0")) > 0 && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_bungacicilan||"Bunga Cicilan"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<b>{UFunc.formatAngka(persen_bunga_cicilan)} %</b>
				</CCol>
				</>
				)}

				{(alamat_dokter||"")!=="" && (
				<>
				<CCol xs="3" className="px-1 d-md-none text-start">{uBahasaObj.word_alamat||"Alamat"}</CCol>
				<CCol xs="9" className="px-1 d-md-none">
					<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
				</CCol>
				</>
				)}

				{(spesialisasi||"")!=="" && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_spesialisasi||"Spesialisasi"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<span className="text-uppercase">{UFunc.renderHTML(vSpesialis)}</span>
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="8" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				<CCol md="2" lg="1" className="px-0 text-md-center d-none d-md-block">
					<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
					</CTooltip>

					{uIsHapusallow && (
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		return (
		<CForm className="mx-lg-3">
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<small className="d-md-none">{uBahasaObj.caption_namdokter||"Nama Dokter"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_namdokter||"Nama Dokter"}<small className="ms-1">DRG.</small></span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={30}
				className="text-capitalize"
				value={uDlgformdataObj.nama_dokter||""}
				onChange={(e)=>{uDlgformdataObj.nama_dokter= (e.target.value);setEfekview(!uEfekview)}}
				id="inpnamadokter"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<small className="d-md-none">{uBahasaObj.caption_ihsnumber||"IHSNumber"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_ihsnumber||"IHS Number"}</span>
		</CCol>
		<CCol xs="8" md="6" lg="4" className="">
			<CFormInput size="sm"
				maxLength={30}
				readOnly={true}
				className="text-uppercase"
				value={uDlgformdataObj.ihs_number||""}
				onChange={(e)=>{uDlgformdataObj.ihs_number=(e.target.value);setEfekview(!uEfekview)}}
				id="inpihsnumber"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<small className="d-md-none">{uBahasaObj.word_nik||"NIK"}</small>
			<span className="d-none d-md-block">{uBahasaObj.word_nik||"NIK"}</span>
		</CCol>
		<CCol xs="8" md="6" lg="4" className="">
			<CFormInput size="sm"
				maxLength={50}
				className="text-uppercase"
				value={uDlgformdataObj.nik||""}
				onChange={(e)=>{uDlgformdataObj.nik = (e.target.value);setEfekview(!uEfekview)}}
				id="inpnik"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<small className="d-md-none">{uBahasaObj.word_alamat||"Alamat"}</small>
			<span className="d-none d-md-block">{uBahasaObj.word_alamat||"Alamat"}</span>
		</CCol>
		<CCol className="">
			<CFormTextarea size="sm"
				value={UFunc.br2nl(uDlgformdataObj.alamat_dokter||"")}
				onChange={(e)=>{uDlgformdataObj.alamat_dokter = UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
				id="inpalamat"/>
			<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea}</div>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<small className="d-md-none">{uBahasaObj.word_spesialisasi||"Spesialisasi"}</small>
			<span className="d-none d-md-block">{uBahasaObj.word_spesialisasi||"Spesialisasi"}</span>
		</CCol>
		<CCol xs="8" lg="4" className="">
			<CFormInput size="sm"
				maxLength={30}
				value={uDlgformdataObj.spesialisasi||""}
				onChange={(e)=>{uDlgformdataObj.spesialisasi = (e.target.value);setEfekview(!uEfekview)}}
				onKeyDown={(e)=>{
					if((uTokenObj.userhak||"")==="DOKTER")
						UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
				}}
				className="text-uppercase"
				id="inpspesialis"/>
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>

		{(uConfigappsObj.level_app||"DEMO") === "" && (
		<CRow className="my-2">
		<CCol xs="7" md="4" className="text-md-end fw-bolder">{uBahasaObj.caption_bungacicilan||"Bunga CIcilan"}</CCol>
		<CCol xs="5" md="3" lg="2" className="">
			{((uTokenObj.userhak||"")==="FREEROLE" || (uTokenObj.userhak||"")==="ADMIN") && (
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				pattern="^\d*(\,\d{0,2})?$"
				value={uDlgformdataObj.persen_bunga_cicilan||"0"}
				onChange={(e)=>{uDlgformdataObj.persen_bunga_cicilan = (e.target.value);;setEfekview(!uEfekview)}}
				onFocus={(e)=>e.target.select()}
				className="text-end"
				id="inpbungacicilan"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
			)}
			{((uTokenObj.userhak||"")!=="FREEROLE" && (uTokenObj.userhak||"")!=="ADMIN") && (
				<b className="">{uDlgformdataObj.persen_bunga_cicilan} %</b>
			)}
		</CCol>
		</CRow>
		)}
		
		<CRow className="my-2">
		<CCol xs="7" md="4" className="text-md-end text-warning fw-bolder">
			<small className="d-md-none">{uBahasaObj.caption_persenfee||"Persen Fee"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_persenfee||"Persen Fee"}</span>
		</CCol>
		<CCol xs="5" md="3" lg="2" className="">
			{((uTokenObj.userhak||"")==="FREEROLE" || (uTokenObj.userhak||"")==="ADMIN") && (
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				pattern="^\d*(\,\d{0,2})?$"
				value={uDlgformdataObj.persen_pendapatan||"50"}
				onChange={(e)=>{uDlgformdataObj.persen_pendapatan = (e.target.value);setEfekview(!uEfekview)}}
				onFocus={(e)=>e.target.select()}
				className="text-end"
				id="inppersen"/>
			<CInputGroupText>%</CInputGroupText>
			</CInputGroup>
			)}
			{((uTokenObj.userhak||"")!=="FREEROLE" && (uTokenObj.userhak||"")!=="ADMIN") && (
				<b className="text-success">{uDlgformdataObj.persen_pendapatan} %</b>
			)}
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="5" md="4" className="text-md-end">
			<small className="d-md-none">{uBahasaObj.caption_pilihusername||"Username Login"}</small>
			<span className="d-none d-md-block">{uBahasaObj.caption_pilihusername||"Username Login"}</span>
		</CCol>
		<CCol xs="7" md="8" className="">
			<CFormSelect size="sm"
				value={uDlgformdataObj.pengguna_id}
				disabled={(uTokenObj.userhak||"")==="DOKTER"}
				onChange={(e)=>{uDlgformdataObj.pengguna_id = (e.target.value);;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpuserid">
			<option>--PILIH Username Login</option>
			{uDatauserArr.map((vItems,vKeys)=>{
				return (
				<option key={vKeys} value={vItems.id}>{(vItems.username||"").toUpperCase()} ({vItems.usercaption})</option>
				)
			})}
			</CFormSelect>
			<div className="classpetunjuk">
			*) Bila Tidak Terdaftar, Silahkan Input Data User Terlebih Dahulu
			</div>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_dokter || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Dokter - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Dokter - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaduser = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL||0);

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Dokter - apiLoaduser) LEWaT "); 
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/ld_user";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatauserArr(JSON.parse(output_string.datauser||"[]"))
				setDlgformshow(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Dokter - apiLoaduser) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Dokter - apiLoaduser) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiRegisterihs = (_IDTABEL) => {
		_IDTABEL 				= parseInt(_IDTABEL)||0;
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiRegisterihs); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiRegisterihs); return;
		}

		const vElbutton		= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_accesstoken : vAccesstoken,
			send_nik : uDlgformdataObj.nik||"",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/pr_registerihs";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then(async(output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				const vIHSnumber = output_string.ihs_number || "";
				uDlgformdataObj.ihs_number = vIHSnumber;

				const vIHSmessage = output_string.ihs_message || "";
				if(vIHSmessage !== "") showToast("SATUSEHAT: <b>"+vIHSmessage+"</b>");

				apiProsessimpan(_IDTABEL);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Dokter - apiRegisterihs) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Dokter - apiRegisterihs) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxuser	= UFunc.getObjectIndeks(uDatauserArr,uDlgformdataObj.pengguna_id)
		const vUsername	= uDatauserArr[vIdxuser].username;
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Dokter - apiProsessimpan) LEWaT "); 
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_datajson: JSON.stringify(uDlgformdataObj),
			/*send_namadokter : uDlgformdataObj.nama_dokter,
			send_userid : uDlgformdataObj.pengguna_id,
			send_alamat : uDlgformdataObj.alamat_dokter,
			send_spesialis : uDlgformdataObj.spesialisasi,
			send_persen : parseFloat(uDlgformdataObj.persen_pendapatan)||0,
			send_bungacicilan : parseFloat(uDlgformdataObj.persen_bunga_cicilan)||0,//-*/
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||"255",
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						dokter_username:vUsername,
						dokter_userid:uDlgformdataObj.pengguna_id,
						nama_dokter:uDlgformdataObj.nama_dokter,
						alamat_dokter: uDlgformdataObj.alamat_dokter||"",
						persen_pendapatan:parseFloat(uDlgformdataObj.persen_pendapatan)||0,
						persen_bunga_cicilan:parseFloat(uDlgformdataObj.persen_bunga_cicilan)||0,
						spesialisasi:uDlgformdataObj.spesialisasi||"",
						nik:uDlgformdataObj.nik||"",
						ihs_number:uDlgformdataObj.ihs_number||"",
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);
					setJmlData(uJmlData+1);

					uDatauserArr.splice(vIdxuser,1);
					initFormAwal();
					setPageActive(1);
					setRowselect(-1);
					if(!isMobile) {
						document.getElementById("inpnamadokter") && 
							document.getElementById("inpnamadokter").focus()
					}
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDatamainArr[vIdxObj].nama_dokter		= uDlgformdataObj.nama_dokter;
					uDatamainArr[vIdxObj].dokter_username 	= vUsername;
					uDatamainArr[vIdxObj].dokter_userid 	= uDlgformdataObj.pengguna_id;
					uDatamainArr[vIdxObj].alamat_dokter		= uDlgformdataObj.alamat_dokter;
					uDatamainArr[vIdxObj].persen_pendapatan = parseFloat(uDlgformdataObj.persen_pendapatan)||0;
					uDatamainArr[vIdxObj].persen_bunga_cicilan 	= parseFloat(uDlgformdataObj.persen_bunga_cicilan)||0;
					uDatamainArr[vIdxObj].spesialisasi 		= uDlgformdataObj.spesialisasi;
					uDatamainArr[vIdxObj].nik 				= uDlgformdataObj.nik;
					uDatamainArr[vIdxObj].ihs_number 		= uDlgformdataObj.ihs_number;

					hdlToggledlg();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Dokter - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Dokter - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vIdxObj,1);
				setRowselect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Dokter - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Dokter - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setDlgformshow(false);
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_dokter || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uDlgformdataObj.dlg_header = ((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		apiLoaduser()
	},[uHandelTambah])
	React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])
	React.useEffect(()=>{
		if(!uISDlgformshow) { initFormAwal(); setDatauserArr([]); return; }

		//console.log("("+cComponentName+"[uISDlgformshow]) uISDlgformshow 1: "+uISDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpnamadokter");
			if(!isMobile) vElFocus && vElFocus.focus();
		},350);
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlgformshow) return;

		if(uIDselect > 0) {
			const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,uIDselect);
			if((uDlgformdataObj.nik||"") !== (uDatamainArr[vArridx].nik||"")) {
				uDlgformdataObj.ihs_number = "";
			} else {
				uDlgformdataObj.ihs_number = uDatamainArr[vArridx].ihs_number;
			}
		} else {
			uDlgformdataObj.ihs_number = "";
		}
	},[uDlgformdataObj,
	uDlgformdataObj.nik
	]);
	//console.log("Dokter - uPageActive => "+JSON.stringify(uPageActive));


	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<b>{uBahasaObj.caption_page500||"Page 500"}</b>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-md-none text-primary">[{UFunc.formatAngka(uJmlData)}]</small>
				<span className="d-md-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
			
			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination 
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i)=>setPageActive(i)}
					align="start"/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.dlg_header}
			toggle={hdlToggledlg}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Dokter