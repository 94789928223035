import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CImage,
  CForm,
  CButton,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CLink,
  CTooltip,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubkompetensidokter = (props) => {
	const { setToken,setLoading, showToast,prosesExpired } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const [uDatadokterArr,setDatadokterArr] = React.useState([]);
	const [uInpdokterID,setInpdokterID]		= React.useState(uKeywordObj.dokterid_selected||"");

	const hdlChangeDokterID = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.dokterid_selected = uInpdokterID; 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			const vDokterID = uTokenObj.userhak==="DOKTER" ? uTokenObj.user_dokterid
				: undefined;
			uKeywordObj.dokterid_selected = vDokterID; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpdokterID(vDokterID||0);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((parseInt(uInpdokterID)||0) > 0) {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	const apiLoaddata = () => {
		const vElemen	= document.getElementById("inpdokterid");
		vElemen && (vElemen.disabled = true);

		const vDATAS	= JSON.stringify({
			send_keyword : "",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_dokter/ld_data";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			vElemen && (vElemen.disabled = false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatadokterArr(vTmpObj);
				localStorage.setItem("dokterlist",output_string.dataobject);
			} else if(output_string.info) {
				console.log("(Headersubkompetensidokter - apiLoaddata) output_string.info : "+output_string.info);
			} else if(output_string.errors) {
				console.log("(Headersubkompetensidokter - apiLoaddata) output_string.errors : "+output_string.errors);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElemen && (vElemen.disabled = false);
			console.log("(Headersubkompetensidokter - apiLoaddata) catch-error : "+error);
		});
	}

	React.useEffect(()=>{
		if(uTokenObj.userhak !== "DOKTER") {
			setInpdokterID("0");
			apiLoaddata()
		} else setInpdokterID(uTokenObj.user_dokterid);

		return ()=> {
			setDatadokterArr([])
			localStorage.removeItem("dokterlist")
			uKeywordObj.dokterid_selected = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		}
	},[]);
	React.useEffect(()=>{ hdlChangeDokterID() },[uInpdokterID]);

	//console.log("Headersubkompetensidokter - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm>
			{(uTokenObj.userhak !== "DOKTER") && (
			<CFormSelect size="sm"
				style={{minWidth:295,width:"auto"}}
				value={uInpdokterID}
				onChange={(e)=>setInpdokterID(e.target.value)}
				id="inpdokterid">
			<option>--PILIH NAMA DOKTER</option>
			{uDatadokterArr.map((vItems,vKeys)=>{
				return (
				<option key={vKeys} value={vItems.id}>{(vItems.dokter_caption||"UNDF").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			)}
			{(uTokenObj.userhak === "DOKTER") && (
				<center><b>{uTokenObj.usercaption||"UNDF"}</b></center>
			)}
		</CForm>

		<div className="ms-1">
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="d-none d-md-inline">
				<span className="mx-1">&middot;</span>
				(<b id="idjmldata" className="text-primary">0</b> {uBahasaObj.word_data||"Data"})
			</span>
		</div>
		</>
	)
}

export default Headersubkompetensidokter
